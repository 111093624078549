import React from "react";

const OrderStatus = ({ status, color }) => {
  const colorMap = {
    black: "#000000",
    silver: "#C0C0C0",
    gray: "#808080",
    white: "#FFFFFF",
    maroon: "#800000",
    red: "#FF0000",
    purple: "#800080",
    fuchsia: "#FF00FF",
    green: "#008000",
    lime: "#00FF00",
    olive: "#808000",
    yellow: "#FFFF00",
    orange: "#ffa500",
    navy: "#000080",
    blue: "#0000FF",
    teal: "#008080",
    aqua: "#00FFFF",
    // Add more colors as needed
  };

  const bgColor = (color) => {
    const lowercaseColor = color.toLowerCase();
    if (lowercaseColor in colorMap) {
      const hexColor = colorMap[lowercaseColor];
      let [r, g, b] = hexColor.match(/\w\w/g).map((x) => parseInt(x, 16));

      // Calculate the luminance (perceived brightness) of the color
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

      // Increased the percentage for lighter shade
      const percent = luminance < 0.5 ? 80 : 85;

      const R = Math.round(r + ((255 - r) * percent) / 100);
      const G = Math.round(g + ((255 - g) * percent) / 100);
      const B = Math.round(b + ((255 - b) * percent) / 100);

      return (
        "#" +
        [R, G, B]
          .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
          })
          .join("")
      );
    } else {
      return "white";
    }
  };

  return (
    <p
      className="order-status"
      style={{
        backgroundColor: bgColor(color),
        color: color,
      }}
    >
      {status}
    </p>
  );
};

export default OrderStatus;
