import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./resources.css";

const ResourcesSidebar = (props) => {
  const navigate = useNavigate();
  const [activeResource, setActiveResource] = useState("");
  useEffect(() => {
    setActiveResource(props.activeResource);
  }, [props]);

  return (
    <div className="policy-sidebar">
      <div className="policy-sidebar-heading">RESOURCES</div>
      <div className="policy-sidebar-container">
        <div
          className={
            activeResource == "Privacy Policy"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy
        </div>
        <div
          className={
            activeResource == "Cookie Policy"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/cookie-policy");
          }}
        >
          Cookie Policy
        </div>
        {/* <div
          className={
            activeResource == "Return Policy"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/return-policy");
          }}
        >
          Return Policy
        </div> */}
        {/* <div
          className={
            activeResource == "Refund Policy"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/refund-policy");
          }}
        >
          Refund Policy
        </div> */}
        {/* <div
          className={
            activeResource == "Shipping Policy"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/shipping-policy");
          }}
        >
          Shipping Policy
        </div> */}

        <div
          className={
            activeResource == "Terms & Conditions"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/terms-and-condition");
          }}
        >
          Terms & Conditions
        </div>
        {/* <div
          className={
            activeResource == "Terms Of Use"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/terms-of-use");
          }}
        >
          Terms Of Use
        </div> */}
        {/* <div
          className={
            activeResource == "FAQ's"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/faq");
          }}
        >
          FAQ's
        </div> */}
        <div
          className={
            activeResource == "Seller's GuideLine"
              ? "policy-sidebar-list-item active-policy"
              : "policy-sidebar-list-item"
          }
          onClick={() => {
            navigate("/seller-guideline");
          }}
        >
          Seller's GuideLine
        </div>
      </div>
    </div>
  );
};

export default ResourcesSidebar;
