import { useState } from "react";
import EmailVerified from "../../../Assets/email_verified.png";
import { useNavigate } from "react-router-dom";
import "./EmailVerified.css";
const EmailVerifedPage = () => {
  return (
    <div className="pageNot_Found_main_Container">
      <div className="pageNotFoundContainer">
        <div className="page_notfound_img_Container">
          <img className="notfound_image" src={EmailVerified} alt="" />
        </div>
        <div className="">
          <h1 className="email-verified-text">
            {" "}
            Your Email has been verified by JOYREJOY{" "}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default EmailVerifedPage;
