import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppNavbar from "./Pages/AppNavbar/AppNavbar";
import InputContext from "../src/Pages/Auth/InputContext";
import { ToastContainer } from "react-toastify";
import "./Assets/style.css";

// import pages
import Home from "./Pages/Home/Home";

import Login from "./Pages/Auth/Login/Login";
import ForgotPassword from "./Pages/Auth/ForgotPassword/ForgotPassword";
import Signup from "./Pages/Auth/Signup/Signup";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ProfileNavigation from "./Pages/Profile/ProfileNavigation";
import Profile from "./Pages/Profile/Profile";
import Orders from "./Pages/Orders/Orders";
import OrderDetails from "./Pages/Orders/OrderDetails";
import Payments from "./Pages/Payments/Payments";
import ProductList from "./Pages/Products/ProductList";
import Product from "./Pages/Products/Product";
import ProductVariant from "./Pages/Products/ProductVariant";
import DonationList from "./Pages/Donation/DonationList";
import DonationDetails from "./Pages/Donation/DonationDetails";
import NGOs from "./Pages/Donation/NGOs";
import NgoDonate from "./Pages/Donation/NgoDonate";

// Other components
import CacheBusterComponent from "./Component/CacheBuster/CacheBuster";
import RouteNotFound from "./Component/NoRoutes/RouteNotFound";
import AppVersion from "../src/Component/AppVersion/AppVersion";
import Header from "./Pages/Home/Header/Header";
import FAQs from "./Pages/Resources/faq";
import CookiePolicy from "./Pages/Resources/cookiePolicy";
import PrivacyPolicy from "./Pages/Resources/privacyPolicy";
import RefundPolicy from "./Pages/Resources/refundPolicy";
import ReturnPolicy from "./Pages/Resources/returnPolicy";
import SellersGuideLine from "./Pages/Resources/sellersGuideline";
import ShippingPolicy from "./Pages/Resources/shippingPolicy";
import TermsOfUse from "./Pages/Resources/termsOfUse";
import TermsAndCondition from "./Pages/Resources/termsAndCondition";
import EmailVerifedPage from "./Pages/Auth/EmailVerified/EmailVerified";

import ServiceUnavailable from "./Pages/serviceUnavailable/serviceUnavailable";
// constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const App = () => {
  const contextObj = useContext(InputContext);
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [sellerStatus, setSellerStatus] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({
    canShowProfile: false,
    canShowDonation: false,
    canShowProducts: false,
    canAddProducts: false,
    canEditProducts: false,
    canDeleteProducts: false,
    canShowPayments: false,
    canShowOrders: false,
    canShowDashboard: false,
    canLogin: false,
    canSignup: false,
    sellerCommonNavigationbar: false,
    sellerProfileinformation: false,
    sellerProfilePaymentinformation: false,
    sellerProfilePickupaddress: false,
    canSellerProfileDeleteAccount: false,
    canSellerProfileChangePassword: false,
    canSellerForgot_Password: false,
    sellerFeedback: false,
    sellerFeedbackSurvey: false,
  });

  // Handle window re-size
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.outerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Default Toaster Settings
  const defaultToastOptions = {
    autoClose: 2000,
    stacked: true,
    position: windowWidth > 600 ? "top-center" : "bottom-center",
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "light",
    limit: 3,
  };

  const toggleClick = () => {
    setIsToggleOn((prevToggle) => !prevToggle);
  };
  const fetchFeatureFlags = async () => {
    try {
      const response = await fetch(`${API_HOST}/api/flags?type=Seller`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        let featureFlagsArray = {};
        res.data.forEach((data) => {
          featureFlagsArray[data.name] = data.value;
        });
        contextObj.setInput("featureFlags", featureFlagsArray);
        setFeatureFlags((prev) => ({
          ...prev,
          canShowProfile: featureFlagsArray.seller_profile,
          canShowDonation: featureFlagsArray.seller_donation,
          canShowProducts: featureFlagsArray.seller_products,
          canAddProducts: featureFlagsArray.seller_products_add,
          canEditProducts: featureFlagsArray.seller_products_edit,
          canDeleteProducts: featureFlagsArray.seller_products_delete,
          canShowPayments: featureFlagsArray.seller_payments,
          canShowOrders: featureFlagsArray.seller_orders,
          canShowDashboard: featureFlagsArray.seller_dashboard,
          canLogin: featureFlagsArray.seller_login,
          canSignup: featureFlagsArray.seller_registration,
          sellerCommonNavigationbar:
            featureFlagsArray.seller_common_navigationbar,
          sellerProfileinformation:
            featureFlagsArray.seller_profile_profileinformation,
          sellerProfilePaymentinformation:
            featureFlagsArray.seller_profile_paymentinformation,
          sellerProfilePickupaddress:
            featureFlagsArray.seller_profile_pickupaddress,
          canSellerProfileDeleteAccount:
            featureFlagsArray.seller_profile_deleteaccount,
          canSellerProfileChangePassword:
            featureFlagsArray.seller_profile_changepassword,
          canSellerForgot_Password: featureFlagsArray.seller_forgot_password,
          sellerFeedback: featureFlagsArray.seller_feedback,
          sellerFeedbackSurvey: featureFlagsArray.seller_feedback_survey,
        }));
      } else {
        console.error(res);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (contextObj.inputValue.featureFlags === null) {
      fetchFeatureFlags();
    }
  }, [contextObj.inputValue.featureFlags]);

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
  }, [contextObj.inputValue.isSellerVerified]);

  return (
    <div className="App">
      <CacheBusterComponent />
      <Router>
        <ToastContainer {...defaultToastOptions} />
        <Routes>
          <>
            <Route
              exact
              path="/"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              exact
              path="/version"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <AppVersion />
                )
              }
            />
            <Route
              exact
              path="/email-verified"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <EmailVerifedPage />
                  </>
                )
              }
            />
            <Route
              exact
              path="/faq"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <FAQs />
                  </>
                )
              }
            />
            <Route
              exact
              path="/cookie-policy"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <CookiePolicy />
                  </>
                )
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <PrivacyPolicy />
                  </>
                )
              }
            />
            <Route
              exact
              path="/refund-policy"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <RefundPolicy />
                  </>
                )
              }
            />
            <Route
              exact
              path="/return-policy"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <ReturnPolicy />
                  </>
                )
              }
            />
            <Route
              exact
              path="/seller-guideline"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <SellersGuideLine />
                  </>
                )
              }
            />
            <Route
              exact
              path="/shipping-policy"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <ShippingPolicy />
                  </>
                )
              }
            />
            <Route
              exact
              path="/terms-of-use"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <TermsOfUse />
                  </>
                )
              }
            />
            <Route
              exact
              path="/terms-and-condition"
              element={
                contextObj.inputValue.serviceUnavailable ? (
                  <ServiceUnavailable />
                ) : (
                  <>
                    <Header />
                    <TermsAndCondition />
                  </>
                )
              }
            />
            {isloading === true ? (
              <></>
            ) : (
              <>
                {/* <Route
                  exact
                  path="/serviceUnavailable"
                  element={<ServiceUnavailable />}
                /> */}
                <Route
                  exact
                  path="/forgot-password"
                  element={
                    contextObj.inputValue.serviceUnavailable ? (
                      <ServiceUnavailable />
                    ) : (
                      <ForgotPassword />
                    )
                  }
                />
                {/* <Route
                  exact
                  path="/update-password"
                  element={<UpdatePassword />}
                /> */}
                {featureFlags.canLogin && (
                  <Route
                    exact
                    path="/login"
                    element={
                      contextObj.inputValue.serviceUnavailable ? (
                        <ServiceUnavailable />
                      ) : (
                        <Login />
                      )
                    }
                  />
                )}
                {featureFlags.canSignup && (
                  <Route
                    exact
                    path="/signup"
                    element={
                      contextObj.inputValue.serviceUnavailable ? (
                        <ServiceUnavailable />
                      ) : (
                        <Signup />
                      )
                    }
                  />
                )}
                {featureFlags.canShowDashboard && (
                  <Route
                    exact
                    path="/dashboard"
                    element={
                      contextObj.inputValue.serviceUnavailable ? (
                        <ServiceUnavailable />
                      ) : (
                        <>
                          <AppNavbar onToggleClick={toggleClick} />
                          <Dashboard isToggleOn={isToggleOn} />
                        </>
                      )
                    }
                  />
                )}
                {featureFlags.canShowProfile && (
                  <Route
                    exact
                    path="/profile"
                    element={
                      contextObj.inputValue.serviceUnavailable ? (
                        <ServiceUnavailable />
                      ) : (
                        <>
                          <AppNavbar />
                          <ProfileNavigation />
                        </>
                      )
                    }
                  />
                )}
                {featureFlags.sellerProfileinformation && (
                  <Route
                    exact
                    path="/profiles"
                    element={
                      contextObj.inputValue.serviceUnavailable ? (
                        <ServiceUnavailable />
                      ) : (
                        <>
                          <AppNavbar />
                          <Profile />
                        </>
                      )
                    }
                  />
                )}
                {featureFlags.canShowOrders && (
                  <>
                    <Route
                      exact
                      path="/orders"
                      element={
                        contextObj.inputValue.serviceUnavailable ? (
                          <ServiceUnavailable />
                        ) : (
                          <>
                            <AppNavbar />
                            <Orders />
                          </>
                        )
                      }
                    />
                    <Route
                      exact
                      path="/order-details/:orderId"
                      element={
                        contextObj.inputValue.serviceUnavailable ? (
                          <ServiceUnavailable />
                        ) : (
                          <>
                            <AppNavbar />
                            <OrderDetails />
                          </>
                        )
                      }
                    />
                  </>
                )}
                {featureFlags.canShowPayments && (
                  <>
                    <Route
                      exact
                      path="/payments"
                      element={
                        contextObj.inputValue.serviceUnavailable ? (
                          <ServiceUnavailable />
                        ) : (
                          <>
                            <AppNavbar />
                            <Payments />
                          </>
                        )
                      }
                    />
                  </>
                )}
                {featureFlags.canShowProducts && (
                  <>
                    <Route
                      exact
                      path="/products"
                      element={
                        contextObj.inputValue.serviceUnavailable ? (
                          <ServiceUnavailable />
                        ) : (
                          <>
                            <AppNavbar />
                            <ProductList />
                          </>
                        )
                      }
                    />
                    {!sellerStatus && (
                      <>
                        {featureFlags.canAddProducts && (
                          <Route
                            exact
                            path="/products/new"
                            element={
                              contextObj.inputValue.serviceUnavailable ? (
                                <ServiceUnavailable />
                              ) : (
                                <>
                                  <AppNavbar />
                                  {/* <Product page={"new"} /> */}
                                  <ProductVariant page={"new"} />
                                </>
                              )
                            }
                          />
                        )}
                        {featureFlags.canAddProducts && (
                          <Route
                            exact
                            path="/products/new-variant"
                            element={
                              contextObj.inputValue.serviceUnavailable ? (
                                <ServiceUnavailable />
                              ) : (
                                <>
                                  <AppNavbar />
                                  <ProductVariant page={"new"} />
                                </>
                              )
                            }
                          />
                        )}
                        {/* {featureFlags.canEditProducts && (
                          <Route
                            exact
                            path="/products/:slug"
                            element={
                              contextObj.inputValue.serviceUnavailable ? (
                                <ServiceUnavailable />
                              ) : (
                                <>
                                  <AppNavbar />
                                  <Product page={"edit"} />
                                </>
                              )
                            }
                          />
                        )} */}
                        {featureFlags.canEditProducts && (
                          <Route
                            exact
                            path="/products/:slug"
                            element={
                              contextObj.inputValue.serviceUnavailable ? (
                                <ServiceUnavailable />
                              ) : (
                                <>
                                  <AppNavbar />
                                  <ProductVariant page={"edit"} />
                                </>
                              )
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {featureFlags.canShowDonation && (
                  <>
                    <Route
                      exact
                      path="/donations"
                      element={
                        contextObj.inputValue.serviceUnavailable ? (
                          <ServiceUnavailable />
                        ) : (
                          <>
                            <AppNavbar />
                            <DonationList />
                          </>
                        )
                      }
                    />
                    <Route
                      exact
                      path="/donations/:donationId"
                      element={
                        contextObj.inputValue.serviceUnavailable ? (
                          <ServiceUnavailable />
                        ) : (
                          <>
                            <AppNavbar />
                            <DonationDetails />
                          </>
                        )
                      }
                    />
                    <Route
                      exact
                      path="/ngos"
                      element={
                        contextObj.inputValue.serviceUnavailable ? (
                          <ServiceUnavailable />
                        ) : (
                          <>
                            <AppNavbar />
                            <NGOs />
                          </>
                        )
                      }
                    />
                    <Route
                      exact
                      path="/ngo-donate/:ngoId"
                      element={
                        contextObj.inputValue.serviceUnavailable ? (
                          <ServiceUnavailable />
                        ) : (
                          <>
                            <AppNavbar />
                            <NgoDonate />
                          </>
                        )
                      }
                    />
                  </>
                )}

                <Route
                  path="*"
                  element={
                    contextObj.inputValue.serviceUnavailable ? (
                      <ServiceUnavailable />
                    ) : (
                      <>
                        <AppNavbar />
                        <RouteNotFound />
                      </>
                    )
                  }
                />
              </>
            )}
          </>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
