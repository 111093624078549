import React from "react";
import { useState, useEffect,  } from "react";
import Footer from "../Home/Footer/Footer";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";

const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;

const ReturnPolicy = () => {
  
  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{
      backgroundColor : "#fff"
    }}>
      <div
        className="body-container"
    
      >
        <div className="policy-container" >
          <ResourcesSidebar activeResource={"Return Policy"} />
          <div className="policy-content">
            <h1
              className={`policy-content-heading ${
                isSticky ? "sticky-heading" : ""
              }`}
            >
              Return Policy
            </h1>
            <p>
              <span>
                Please get in touch with us within 48 hours of receiving the
                goods (along with a picture of the damaged item) if you receive
                one that is damaged when it is delivered. We'll work out a
                solution that works for both of us. We are a trustworthy group,
                and our main goal is to provide you with the best service
                possible.
              </span>
            </p>
            <p>
              <span>
                We apologize that we are unable to allow refunds just because
                you changed your mind because we want to keep these previously
                owned, PreHugged things cheap for everyone. We hope you'll
                comprehend.
              </span>
              <span>
                There may be pieces missing because these are previously owned,
                PreHugged items. All of these specifics are highlighted in the
                product description. Prior to placing your order, kindly read it
                thoroughly.
              </span>
            </p>
            <p>
              <span>
                Contact us at{" "}
                <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a> if you have
                any questions.
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReturnPolicy;
