import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import InputProvider from "./Pages/Auth/InputProvider";
import "./index.css";
import App from "./App";

const clearCacheAndReload = async () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.location.reload(true);
  }
};

// Check if it's the first time the app is loaded
let isFirstLoad = true;
try {
  const isFirstLoadString = sessionStorage.getItem("isFirstLoad");
  if (isFirstLoadString !== null) {
    isFirstLoad = JSON.parse(isFirstLoadString);
  }
} catch (error) {
  console.error("Error reading isFirstLoad from sessionStorage:", error);
}
if (isFirstLoad) {
  try {
    sessionStorage.setItem("isFirstLoad", JSON.stringify(false));
  } catch (error) {
    console.error("Error writing isFirstLoad to sessionStorage:", error);
  }

  clearCacheAndReload();
}
const rootElement = document.getElementById("root");

ReactDOM.createRoot(rootElement).render(
  <InputProvider>
    <App />
  </InputProvider>
);

reportWebVitals();
