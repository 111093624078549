import React, { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import Alert from "../../Configs/Alerts.json";
import loadingImgPath from "../../Assets/rolling.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import InputContext from "../Auth/InputContext";
import Toast from "../../Component/Toastify/Toast";
import StatusCell from "../../Component/StatusCell";
import "../Products/product.css";
import Paginate from "../../Component/Pagination/Paginate";
import InfiniteScroll from "react-infinite-scroll-component";
import imagePlaceHolder from "../../Assets/image-placeholder.png";
import OrderStatus from "../../Component/OrderStatus";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const OrdersTable = () => {
  const location = useLocation();
  const [scroll, setScroll] = useState(true);
  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const [sellerStatus, setSellerStatus] = useState(false);
  const [isBusinessOwner, setIsBusinessOwner] = useState(false);
  const navigate = new useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();
  let [currentPageIndex, setCurrentPageIndex] = useState(0);
  const OrdersPerAPIResponse = 15;
  let [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState("");
  const [isInMobileView, setIsInMobileView] = useState(false);
  useEffect(() => {
    setIsInMobileView(window.innerWidth <= 600);
  }, []);
  let [url, setURL] = useState(`${API_HOST}/${ApiEndpoint.SellersOrdersApi}?`);
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);

    if (searchQuery !== "") {
      data = data + "&search=" + searchQuery;
    }
    fetchData(data);
  };

  // get all orders

  const fetchData = async (url) => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${navigation.home}`);
      return;
    }

    if (url) {
      // setLoading(true);
      const response = await fetch(`${url}&per_page=${OrdersPerAPIResponse}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const res = await response.json();
      if (res.status === 200) {
        setScroll(res.data.current_page < res.data.last_page);
        if (res.data.total) {
          setTotalOrders(res.data.total);
        } else {
          setTotalOrders(0);
        }
        setPagination(res.data);
        setURL(res.data.next_page_url);
        setLastPage(res.data.last_page);
        const dataArray = res.data.data;
        let updatedData;
        if (window.innerWidth <= 600) {
          if (currentPage == 1) {
            updatedData = [...dataArray];
          } else {
            updatedData = [...data, ...dataArray];
          }
        } else {
          setData([]);
          setFilteredData([]);
          updatedData = res.data.data;
        }
        setData(updatedData);
        setFilteredData(updatedData);
        setSellerStatus(false);
        setCurrentPage(res.data.current_page);
        contextObj.setInput("isSellerVerified", "true");
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("R3p7uLw9Xk", "/");
        removeDataFromCookie("isLoggedIn", "/");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");

        contextObj.setInput("isSignupNavigation", true);
        navigate(`${navigation.home}`);
        showToast("info", Alert.alertForLoginExpired);
      } else if (res.status === 401 && res.message.includes("not verified")) {
        setSellerStatus(true);
        contextObj.setInput("isSellerVerified", "false");
      } else if (res.status === 404) {
        setData([]);
        setFilteredData([]);
        contextObj.setInput("isSellerVerified", "true");
      } else {
        console.error(res);
      }

      setLoading(false);
      setSearchLoading(false);
    }
  };

  // handle search api calls
  useEffect(() => {
    setSearchLoading(true);
    const debounceTimeout = setTimeout(() => {
      currentPage = 1;
      url = `${API_HOST}/${ApiEndpoint.SellersOrdersApi}?page_no=1`;
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
        fetchData(url);
      } else {
        fetchData(url);
      }
    }, 500);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
    if (contextObj.inputValue.isBusinessOwner === true) {
      setIsBusinessOwner(true);
    }
  }, [
    contextObj.inputValue.isSellerVerified,
    contextObj.inputValue.isBusinessOwner,
  ]);

  useEffect(() => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      navigate(`${navigation.home}`);
      showToast("info", Alert.alertForLoginExpired);
    }
    fetchData(url);
  }, []);

  const handleEdit = (id) => {
    navigate(`${navigation.orderDetails}/${id}`);
    return;
  };

  const colorMap = {
    black: "#000000",
    silver: "#C0C0C0",
    gray: "#808080",
    white: "#FFFFFF",
    maroon: "#800000",
    red: "#FF0000",
    purple: "#800080",
    fuchsia: "#FF00FF",
    green: "#008000",
    lime: "#00FF00",
    olive: "#808000",
    yellow: "#FFFF00",
    navy: "#000080",
    blue: "#0000FF",
    teal: "#008080",
    aqua: "#00FFFF",
    // Add more colors as needed
  };

  const bgColor = (color) => {
    const lowercaseColor = color.toLowerCase();
    if (lowercaseColor in colorMap) {
      const hexColor = colorMap[lowercaseColor];
      let [r, g, b] = hexColor.match(/\w\w/g).map((x) => parseInt(x, 16));

      // Calculate the luminance (perceived brightness) of the color
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

      // Increased the percentage for lighter shade
      const percent = luminance < 0.5 ? 80 : 60;

      const R = Math.round(r + ((255 - r) * percent) / 100);
      const G = Math.round(g + ((255 - g) * percent) / 100);
      const B = Math.round(b + ((255 - b) * percent) / 100);

      return (
        "#" +
        [R, G, B]
          .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
          })
          .join("")
      );
    } else {
      return "white";
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",

        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * OrdersPerAPIResponse,
      },
      {
        Header: "OD No.",
        accessor: "order_number",
      },
      {
        Header: "",
        accessor: "product.thumb_image",
        filterable: false,
        Cell: ({ value }) =>
          value ? (
            <img
              id="sellers-productImage"
              src={`${IMG_HOST}/${value}`}
              onError={(e) => {
                e.target.src = imagePlaceHolder;
              }}
            />
          ) : (
            <img id="sellers-productImage" src={imagePlaceHolder} />
          ),
      },
      {
        Header: "Product",
        accessor: "product.name",

        Cell: ({ value }) => (
          <div
            id="sellers-productName-headingDiv"
            title={value}
            style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              maxWidth: "400px",
            }}
          >
            {value}
          </div>
        ),
      },

      {
        Header: "Product Price",
        accessor: "product.product_price",
        Cell: ({ value }) => (
          <div id="sellers-productSellingPrice" className="cell-container">
            ₹{formatNumberWithCommas(value)}
          </div>
        ),
      },

      {
        Header: "Date & Time",
        accessor: "created_at",
        Cell: ({ value }) => {
          const date = new Date(value);
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          const formattedDate = date.toLocaleString(undefined, options);
          const formattedTime = date.toLocaleTimeString();
          return (
            <div id="sellers-orderTimestamp">
              {formattedDate} {formattedTime}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "order_status",
        Cell: ({ value }) => (
          <OrderStatus status={value.status} color={value.color} />
        ),
      },
      {
        Header: "",
        accessor: "uniqueAccessor",
        Cell: ({ row }) => (
          <div
            id="sellers-editProductButton"
            onClick={() => handleEdit(row.original.id)}
            className="action-icon eye-button"
            title="View Order"
          >
            {/* <FontAwesomeIcon icon={faEye} /> */}
            <span style={{ fontSize: "11px" }}>More Details</span>
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.68205 4.5L0.5 1.38875L1.40897 0.5L5.5 4.5L1.40897 8.5L0.5 7.61125L3.68205 4.5Z"
                fill="#3366CC"
              />
            </svg>
          </div>
        ),
      },
    ],
    [currentPage]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
    filterable,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPageIndex,
        pageSize: OrdersPerAPIResponse,
        globalFilter: "",
      },

      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
      autoResetFilters: false,
    },
    useGlobalFilter,
    //useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  const handleSearchformobile = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredProducts = data.filter(
      (Product) =>
        Product.product.name.toLowerCase().includes(term.toLowerCase()) ||
        Product.order_status.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredData(filteredProducts);
  };

  if (!data) {
    return null;
  }

  function formatNumberWithCommas(number) {
    if (number === null || number === undefined) {
      return ""; // Handle the case when the number is null or undefined
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="full-container">
      <div className="container">
        {sellerStatus && !isBusinessOwner && (
          <div className="seller-verification-line">
            <p>
              Your account verification is pending. To proceed, please{" "}
              <span
                className="complete-your-profile"
                onClick={() => {
                  navigate(`${navigation.profile}`);
                }}
              >
                ensure your Profile Information and Pickup Address is complete
              </span>{" "}
              and wait for the admin verification call.
            </p>
          </div>
        )}
        {sellerStatus && isBusinessOwner && (
          <div className="seller-verification-line">
            <p>
              Your account verification is pending. To proceed, please{" "}
              <span
                className="complete-your-profile"
                onClick={() => {
                  navigate(`${navigation.profile}`);
                }}
              >
                ensure your Profile Information, Payment Information, Pickup
                Address and Business Details is complete
              </span>{" "}
              and wait for the admin verification call.
            </p>
          </div>
        )}
        <div className="container-heading">
          <h2>Orders</h2>
          {/* search bar */}
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
              }}
            />
          </div>
        </div>
        <div className="InnerTableContainer">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => (
                    <th>
                      <div
                        id={`sellersTableHeader_${index}`}
                        className="header-cell"
                        style={{
                          display: "flex",
                        }}
                      >
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{ backgroundColor: "#FFFFFF" }}
            >
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="loadingIconDivForProductPage">
                      <img
                        className="PageLoaingImageMobileForProduct"
                        src={loadingImgPath}
                      />
                    </div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell, index) => (
                        <td
                          id={`sellers-ordertable_${index}`}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className="nomatchfound">No data</div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>

        {/* table pagination */}

        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Orders"}
            />
          )}
        </div>

        {/* from here the code is written for the mobile view */}
        <div className="productContainerForMobileView">
          {isInMobileView && (
            <div className="">
              <InfiniteScroll
                dataLength={filteredData.length}
                next={() => {
                  if (currentPage !== lastPage) {
                    currentPage = currentPage + 1;
                    let fetchURL = url;
                    if (searchQuery !== "") {
                      fetchURL = fetchURL + "&search=" + searchQuery;
                    }
                    fetchData(fetchURL);
                  }
                }}
                hasMore={scroll}
              >
                {loading ? (
                  <div className="loadingIconDivForProductPage">
                    <img
                      id="sellers-LoadingImg"
                      className="loader"
                      src={loadingImgPath}
                    />
                  </div>
                ) : filteredData.length === 0 ? (
                  <div className="productInnerContainerForMobileView">
                    <p className="NoDataFoundDiv">No data</p>
                  </div>
                ) : (
                  <>
                    {filteredData &&
                      filteredData.map((data, index) => (
                        <div key={index}>
                          <div className="productInnerContainerForMobileView">
                            <div className="imageDivForMobile">
                              <div className="imageInnerDivForMobile">
                                <img
                                  id="sellers-produtImage-forMobile"
                                  className="imageViewForMobile"
                                  src={
                                    data.product.thumb_image
                                      ? IMG_HOST +
                                        "/" +
                                        data.product.thumb_image
                                      : imagePlaceHolder
                                  }
                                  onError={(e) => {
                                    e.target.src = imagePlaceHolder;
                                  }}
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div className="productDetailsForMobile">
                              <div className="productNameDivForDonations">
                                {data.product.name.length > 60 ? (
                                  <p>
                                    {data.product.name.substring(0, 60)}
                                    ...
                                  </p>
                                ) : (
                                  data.product.name
                                )}
                              </div>

                              <div>
                                <p className="spanTagOfProductList">
                                  Product Price:{" "}
                                  <span className="prductPriceInnerSpan">
                                    ₹{data.product.product_price}
                                  </span>
                                </p>
                              </div>
                              <div className="statusOfProductDiv">
                                <OrderStatus
                                  status={data.order_status.status}
                                  color={data.order_status.color}
                                />
                              </div>
                            </div>

                            <div
                              id="sellers-ordersDetails-navigationButton"
                              onClick={() => handleEdit(data.id)}
                              className="arrowButtonForNextpage"
                            >
                              <svg
                                width="7"
                                height="11"
                                viewBox="0 0 7 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.45487 5.5L0 1.22203L1.27256 -2.98023e-07L7 5.5L1.27256 11L0 9.77797L4.45487 5.5Z"
                                  fill="#8C8C8C"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))}
                    <div>
                      {scroll && (
                        <div className="loading-button-order-div">
                          <p className="loading-button-order-table-mobile">
                            Loading...
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default OrdersTable;
