import React from "react";
import version from "../../version";

const AppVersion = () => {
  return (
    <div
      style={{
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>App Version: {version}</p>
    </div>
  );
};
export default AppVersion;
