import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import placeHolder from "../../Assets/image-placeholder.png";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/keyboard";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./swiper.css";

//constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

// install Swiper modules
SwiperCore.use([Navigation]);

const SellerReview = (props) => {
  const stories = props.data;

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= rating; i++) {
      stars.push(
        <span key={i} className="star">
          &#9733;
        </span>
      );
    }
    return stars;
  };

  return (
    <>
      <Swiper
        id="sellers-swiperForHomePage"
        slidesPerView={1}
        centeredSlides={false}
        slidesPerGroupSkip={1}
        spaceBetween={10}
        freeMode={true}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
          1440: {
            slidesPerView: 4,
            spaceBetween: 25,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
          arrow: true,
        }}
        lazy={true}
        modules={[Pagination]}
        navigation={{
          clickable: true,
          style: {
            top: "200px",
          },
        }}
      >
        {stories.map((item) => (
          <SwiperSlide id="seller-swiperSlide">
            <div key={item.id}>
              <img
                src={`${IMG_HOST + "/" + item.image}`}
                onError={(e) => {
                  e.target.src = placeHolder;
                }}
                alt="image"
              />
              <div>
                <h3 className="swipperCustomerName">{item.name}</h3>
                <h4 className="swiperCityAndStateTags">{`${item.city}, ${
                  item.state || ""
                }`}</h4>
                <p className="swippercontent">
                  {item.message.length > 350
                    ? `${item.message.slice(0, 350)}...`
                    : item.message}
                </p>
                <div className="rating">{renderStars(item.rating)}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default SellerReview;
