import React from "react";
import { useState, useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";

const APP_NAME = process.env.REACT_APP_NAME;
const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;
const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME;

const TermsOfUse = () => {
  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
    >
      <div className="body-container">
        <div className="policy-container">
          <ResourcesSidebar activeResource={"Terms Of Use"} />
          <div className="policy-content">
            <h1
              className={`policy-content-heading ${
                isSticky ? "sticky-heading" : ""
              }`}
            >
              Terms of Use
            </h1>
            <p>Introduction</p>
            <p>
              <span>
                Welcome to {APP_NAME}, a place to bring joy to your preloved
                toys. These Terms of Service ("Terms") govern your access to and
                use of the Platform and its associated services (collectively
                referred to as the "Service"). By accessing or using the
                Service, you agree to be bound by these Terms. If you disagree
                with any part of these Terms, you may not use the Service.
              </span>
            </p>
            <p>Definitions</p>
            <ul>
              <li>
                "Platform" means the [website/app/service] owned and operated by
                {COMPANY_NAME}.
              </li>
              <li>
                "Service" means all features, functionalities, and content
                offered through the Platform.
              </li>
              <li>
                "User" means any individual or entity accessing or using the
                Service.
              </li>
            </ul>
            <p>Acceptance of Terms</p>
            <p>
              <span>
                Your use of the Service constitutes your acceptance of these
                Terms. These Terms may be updated from time to time. We will
                notify you of any material changes by posting the new Terms on
                the Platform. You are responsible for reviewing the Terms
                periodically. Your continued use of the Service after the
                posting of any updated Terms constitutes your acceptance of such
                updated Terms.
              </span>
            </p>
            <p>User Conduct and Content</p>
            <p>
              <span>
                You agree to use the Service in a manner that is lawful,
                ethical, and respectful of others. You are prohibited from:
              </span>
            </p>
            <ul>
              <li>Violating any applicable laws or regulations.</li>
              <li>Infringing on the intellectual property rights of others.</li>
              <li>
                Posting or transmitting any harmful, illegal, offensive, or
                discriminatory content.
              </li>
              <li>Impersonating any person or entity.</li>
              <li>
                Interfering with the operation of the Service or any other
                user's use of the Service.
              </li>
              <li>
                Attempting to gain unauthorized access to the Service or its
                systems.
              </li>
            </ul>
            <p>
              <span>
                We reserve the right to terminate your access to the Service for
                any violation of these Terms.
              </span>
            </p>
            <p>Disclaimers and Warranties</p>
            <p>
              <span>
                The Service is provided on an "as is" and "as available" basis.
                We make no warranties, express or implied, regarding the Service
                or its content. The Service may contain errors or inaccuracies.
                We are not responsible for any loss or damage that may result
                from your use of the Service.
              </span>
            </p>
            <p>Limitation of Liability</p>
            <p>
              <span>
                To the fullest extent permitted by law, we will not be liable
                for any direct, indirect, incidental, consequential, or punitive
                damages arising out of or related to your use of the Service,
                even if we have been advised of the possibility of such damages.
              </span>
            </p>
            <p>Intellectual Property</p>
            <p>
              <span>
                The Service and its content are protected by intellectual
                property laws and belong to us or our licensors. You may not use
                any copyrighted, trademarked, or other proprietary content
                without our express written permission.
              </span>
            </p>

            <p>Termination and Suspension</p>
            <p>
              <span>
                We may terminate or suspend your access to the Service at any
                time without notice if you violate these Terms or for any other
                reason.
              </span>
            </p>
            <p>Governing Law and Dispute Resolution</p>

            <p>
              <span>
                These Terms will be governed by and construed in accordance with
                the laws of Gujarat, India, without regard to its conflict of
                laws principles. Any dispute arising out of or relating to these
                Terms will be resolved exclusively through binding arbitration
                in accordance with the rules of the [Arbitration Association].
                The arbitration will be conducted in [Gandhinagar, Gujarat,
                India].
              </span>
            </p>
            <p>Entire Agreement</p>
            <p>
              <span>
                These Terms constitute the entire agreement between you and us
                with respect to your use of the Service.
              </span>
            </p>
            <p>Contact Us</p>
            <p>
              <span>
                If you have any questions about these Terms, please contact us
                at <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
