import React from "react";

const StatusCell = ({ value }) => {
  let backgroundColor;
  let color;
  let text;

  if (value === "Pending") {
    backgroundColor = "#FFF3CD";
    text = "Pending";
  } else if (value === "Confirmed") {
    backgroundColor = "#D4EDDA";
    text = "Confirmed";
  } else if (value === "Seller Pickup Processing") {
    backgroundColor = "#FFF3CD";
    text = "Seller Pickup Processing";
  } else if (value === "Sanitizing and Packaging") {
    backgroundColor = "#E2E3E5";
    text = "Sanitizing and Packaging";
  } else if (value === "Failed in Sanitizing and Packaging") {
    backgroundColor = "#F8D7DA";
    text = "Failed in Sanitizing and Packaging";
  } else if (value === "Seller Return Processing") {
    backgroundColor = "#F8D7DA";
    text = "Seller Return Processing";
  } else if (value === "Seller Return Completed") {
    backgroundColor = "#F8D7DA";
    text = "Seller Return Completed";
  } else if (value === "Passed in Sanitizing and Packaging") {
    backgroundColor = "#D4EDDA";
    text = "Delivered";
  } else if (value === "Buyer Delivery Processing") {
    backgroundColor = "#CCE5FF";
    text = "Delivered";
  } else if (value === "Out For Delivery") {
    backgroundColor = "#D1ECF1";
    text = "Delivered";
  } else if (value === "Buyer Return Processing") {
    backgroundColor = "#D4EDDA";
    text = "Delivered";
  } else if (value === "Buyer Return Completed") {
    backgroundColor = "#D4EDDA";
    text = "Delivered";
  } else if (value === "Delivered") {
    backgroundColor = "#D4EDDA";
    text = "Delivered";
  } else if (value === "Cancelled") {
    backgroundColor = "#F8D7DA";
    text = "Cancelled";
  } else {
    backgroundColor = "#E2E3E5";
    text = value;
  }

  return (
    <p
      className="order-status"
      style={{
        backgroundColor,
        color,
      }}
    >
      {text}
    </p>
  );
};

export default StatusCell;
