import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const OrdersGraph = (props) => {
  const [ordersData, setOrdersData] = useState([]);
  const [numbers, setNumbers] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  const [months] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);

  useEffect(() => {
    setOrdersData(props.data);
  }, [props]);

  useEffect(() => {
    if (months && ordersData) {
      const updatedNumbers = months.map((month) => {
        const monthData = ordersData.find((data) => data.month === month);
        return monthData ? monthData.total : 0;
      });
      setNumbers(updatedNumbers);
    }
  }, [months, ordersData]);

  return (
    <div id="graph-container">
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: months,
          },
        ]}
        series={[{ data: numbers, color: "#b800d8" }]}
      />
    </div>
  );
};

export default OrdersGraph;
