import ReactStars from "react-rating-stars-component";
import "./newFeedback.css";
import { useState,useEffect,useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Toast from "../../../Component/Toastify/Toast";
import loadingImgPath from "../../../Assets/rolling.svg";
import ApiEndpoint from "../../../Configs/APIEndpoints.json";

import InputContext from "../../Auth/InputContext";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const NewFeedBack = ({ closeNewFeedBack }) => {
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [suggestion, setSuggestion] = useState("");
  const [feedbackData, setFeedbackData] = useState({
    questions: [],
  });
  const [loading, setLoading] = useState(true);
  const [ratingError, setRatingError] = useState({});

  // Get FAQ Data

  const getFeedBackData = async () => {
    try {
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.GetSellerNewFeedBackData}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      setLoading(false);
      if (res.status === 200) {
        setQuestions(res.data);
      } else if (res.status === 404) {
        setQuestions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        // navigate("/error");
        contextObj.setInput("serviceUnavailable", true);
      } else {
        // window.location.href = "/error";
      }
    }
  };

  useEffect(() => {
    getFeedBackData();
  }, []);

  useEffect(() => {
    if (suggestion !== null) {
      setFeedbackData((prevState) => ({
        ...prevState,
        suggestion: suggestion,
      }));
    }
  }, [suggestion]);

  // Function to handle rating change
  // Function to handle rating change
  const handleRatingChange = (newRating, questionId) => {
    const existingQuestionIndex = feedbackData.questions.findIndex(
      (question) => question.question_id === questionId.toString()
    );

    // If the question exist
    if (existingQuestionIndex !== -1) {
      setFeedbackData((prevState) => ({
        ...prevState,
        questions: prevState.questions.map((question, index) =>
          index === existingQuestionIndex
            ? { ...question, rating: newRating.toString() }
            : question
        ),
      }));
    } else {
      // If the question doesn't exist
      setFeedbackData((prevState) => ({
        ...prevState,
        questions: [
          ...prevState.questions,
          {
            question_id: questionId.toString(),
            rating: newRating.toString(),
          },
        ],
      }));
    }

    // Clear any existing error for this question
    setRatingError((prevError) => ({
      ...prevError,
      [questionId]: undefined,
    }));

    if (!newRating) {
      setRatingError((prevError) => ({
        ...prevError,
        [questionId]: "This field is required.",
      }));
    }
  };

  // Function to check for initial blank ratings
  const checkInitialBlankRatings = () => {
    const errors = {};
    questions.forEach((question) => {
      const rating = feedbackData.questions.find(
        (q) => q.question_id === question.id.toString()
      )?.rating;
      if (!rating) {
        errors[question.id] = "This filed is required.";
      }
    });
    setRatingError(errors);

    // Check if any errors
    const hasErrors = Object.keys(errors).length > 0;
    return hasErrors;
  };

  const handleSuggestionChange = (event) => {
    const val = event.target.value;
    setSuggestion(val);
  };

  const FeedbackAdd = async () => {
    try {
      if (checkInitialBlankRatings()) {
        return;
      }

      setIsButtonDisable(true);
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.postNewFeedback}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(feedbackData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit feedback.");
      }

      const res = await response.json();

      // Assuming  IsButtonDisable is defined somewhere
      setIsButtonDisable(false);

      if (res.status === 201) {
        showToast(
          "success",
          "Thank you for your feedback. Your Feedback is valued and appreciated by JOYREJOY"
        );
        closeNewFeedBack();
      } else {
        // Handle other status codes if needed
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setIsButtonDisable(false);
      showToast("error", "Failed to submit feedback. Please try again later.");
    }
  };

  return (
    <>
      {loading ? (
        <div></div>
      ) : (
        <>
          <div className="Feedback_New_Modal_container">
            <div className="Feedback_NewModel_Container_b2">
              <div>
                <h1 className="feebback_head_new_tag"> Feedback Form</h1>
              </div>
              <div className="feedback-cross-container">
                {" "}
                <span className="modal-close" onClick={closeNewFeedBack}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </div>
              <div>
                {questions.map((item, index) => (
                  <div key={index}>
                    <p className="new-feedback-question">
                      {item.question}
                      <span className="Is_Required"> *</span>
                    </p>
                    <ReactStars
                      count={5}
                      value={parseInt(
                        feedbackData.questions.find(
                          (q) => q.question_id === item.id.toString()
                        )?.rating || 0
                      )}
                      onChange={(newRating) =>
                        handleRatingChange(newRating, item.id)
                      }
                      size={30}
                      isHalf={false}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ff9f00"
                    />
                    {ratingError[item.id] && (
                      <p className="newFeedback-errors">
                        {ratingError[item.id]}
                      </p>
                    )}
                  </div>
                ))}
                <div>
                  <label className="login-label-tag">
                    <textarea
                      type="text"
                      className="login_input_tag text-area-box-c1"
                      value={suggestion}
                      onChange={handleSuggestionChange}
                      name="suggestion"
                      placeholder="Suggestions"
                    />
                    <span className="login-Span-tags lable-floating-new-feedback">
                      Suggestions
                    </span>
                  </label>
                  <li className="fotter-error">{ratingError.suggestion}</li>
                </div>
              </div>
              <div>
                <button
                  disabled={isButtonDisable}
                  className="feeback-Submit-Button"
                  onClick={FeedbackAdd}
                >
                  {isButtonDisable ? (
                    <img
                      src={loadingImgPath}
                      alt="Loading"
                      className="disabledBtn-Loading"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewFeedBack;
