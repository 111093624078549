import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./TermsConditionsText.css";

function TermsConditionsText(props) {
  const navigate = useNavigate();
  return (
    <span>
      <p className="Terms_Conditions_Text">
        By continuing, I agree to{" "}
        <span
          onClick={() => {
            props.onClose(false);
            navigate("/terms-of-use");
          }}
          className="openPolicyDocs"
        >
          Terms of Use
        </span>{" "}
        &{" "}
        <span
          onClick={() => {
            props.onClose(false);
            navigate("/privacy-policy");
          }}
          className="openPolicyDocs"
        >
          Privacy Policy
        </span>
      </p>
    </span>
  );
}

export default TermsConditionsText;
