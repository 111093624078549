import React from "react";
import { useState, useEffect,  } from "react";
import Footer from "../Home/Footer/Footer";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";

const APP_NAME = process.env.REACT_APP_NAME;

const ShippingPolicy = () => {


  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{
      backgroundColor : "#fff"
    }}>
      <div
        className="body-container"
      
      >
        <div className="policy-container" >
          <ResourcesSidebar activeResource={"Shipping Policy"} />

          <div className="policy-content">
            <h1
              className={`policy-content-heading ${
                isSticky ? "sticky-heading" : ""
              }`}
            >
              Shipping Policy
            </h1>
            <p>
              <span>
                At {APP_NAME}, we believe in delivering quality products and so
                our team is actively involved at every stage of the process.
                Product that is shipped from the seller, reaches the buyer via{" "}
                {APP_NAME} warehouse. The shipping cost from seller to warehouse
                is borne by seller and that of the warehouse to buyer is borne
                by buyer.
              </span>
            </p>
            <p>
              <span>
                As a 3rd party delivery vendor is involved in the process of
                shipping, it might take 5-7 days for the shipment to reach the
                destination. At times, orders may take more time depending upon
                the seller's availability and ability to pack and ship the
                product. We request your patience during these situations.
              </span>
            </p>
            <p>
              <span>
                The cost of return shipping will be incurred by the {APP_NAME}{" "}
                in the event that the customer returns any items that are due to
                the products received in damaged condition or not receiving what
                was ordered.
              </span>
            </p>
            <p>
              <span>
                When your order leaves the warehouse, you'll receive an email
                with the tracking information.
              </span>
            </p>
            <p>
              <span>
                Please be aware that {APP_NAME} cannot be held liable for
                delivery delays brought on by natural disasters, holidays,
                strikes, festivals, or other unforeseen circumstances.
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShippingPolicy;
