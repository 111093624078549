import React, { useState } from "react";
import InputContext from "./InputContext";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";

const InputProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    phone: "",
    email: "",
    FirstNameWords: "",
    password: "",
    payload: "",
    navigation: "false",
    navigationFrom: "",
    featureFlagsAvailable: false,
    featureFlags: null,
    reFetchProfileDetails: false,
    serviceUnavailable: false,
    prevPath: null,
  });

  const setInput = (field, value) => {
    setInputValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <InputContext.Provider value={{ inputValue, setInput }}>
      {children}
    </InputContext.Provider>
  );
};

export default InputProvider;
