import React, { useEffect, useState,useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import navigation from "../../Configs/Navigation.json";
import loadingImgPath from "../../Assets/rolling.svg";
import { getDataFromCookies } from "../../Component/Cookies/Cookie";
import DonationStatus from "./DonationStatus";
import InputContext from "../Auth/InputContext";
// import constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const DonationDetails = () => {
  const contextObj = useContext(InputContext);
  const [order, setOrder] = useState("");
  const { donationId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrder(donationId);
  }, []);

  const fetchOrder = async (donationId) => {
    setLoading(true);
    const url = `${API_HOST}/${ApiEndpoint.donations}/${donationId}`;
    const your_access_token = getDataFromCookies("R3p7uLw9Xk");

    if (!your_access_token) {
      window.location.href = navigation.login;
    }

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${your_access_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          setOrder(res.data);
        } else if (res.status === 404) {
          console.error("Bad Request", res.status);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.message.includes("Failed to fetch")) {
          // navigate("/error");
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
      });
  };

  return (
    <div>
      <div className="container">
        <div id="sellers-navigateToOrderPage" className="container-heading">
          <h2
            className="cursor-pointer"
            onClick={() => {
              navigate(`${navigation.donations}`);
            }}
          >
            <span>
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 4.11623V5.884H3.44772L6.36769 8.75001L5.09417 10L0 5.00003L5.09417 0L6.36769 1.25L3.44751 4.11623H15Z"
                  fill="#939393"
                />
              </svg>
            </span>
            Donation Details
          </h2>
        </div>
        {loading ? (
          <div className="od-loading-container">
            <img className="od-loading-img" src={loadingImgPath} />
          </div>
        ) : (
          <>
            {order ? (
              <div className="orderdetails-body">
                <div className="order-details-content">
                  <div className="content-head">
                    <p>
                      Donation ID
                      <br />
                      <span>{order.id}</span>
                    </p>
                    <p>
                      Timestamp
                      <br />
                      <span>{new Date(order.created_at).toLocaleString()}</span>
                    </p>
                  </div>
                  <div className="content-body">
                    <div className="order-details">
                      <div class="image-details">
                        <img
                          class="order-product-image"
                          src={`${IMG_HOST}/${order.product.thumb_image}`}
                          alt="image"
                          loading="lazy"
                        />
                      </div>
                      <div class="product-details">
                        <div class="product-names">
                          <p>
                            Name
                            <span>{order.product.name}</span>
                          </p>
                        </div>
                        <div class="order-prices">
                          <p>
                            Product Price<span>₹{order.product_price}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {order.shipments && (
                      <div className="shipment-docs shipment-action">
                        <span
                          style={{
                            opacity: "0.4",
                          }}
                        >
                          Click to download :
                        </span>
                        {order.shipments.label && (
                          <a href={order.shipments.label}>Label</a>
                        )}
                        {order.shipments.manifest && (
                          <a href={order.shipments.manifest}>Manifest</a>
                        )}
                        {order.shipments.invoice && (
                          <a href={order.shipments.invoice}>Invoice</a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="order-details-action">
                  <div className="top-div">
                    <h4 className="box-heading">Status</h4>
                    <DonationStatus value={order.status} />
                    {order.pause_reason && (
                      <p
                        className="order-pause-reason"
                        style={{ marginTop: "10px" }}
                      >
                        Note: <span>{order.pause_reason}</span>
                      </p>
                    )}
                  </div>
                  <div className="bottom-div">
                    <h4 className="box-heading">Pickup Address</h4>
                    <div>
                      {order.pickup_address && (
                        <p>
                          {order.pickup_address.street_address}
                          <br />
                          {`${order.pickup_address.city}, ${order.pickup_address.state}`}
                          <br />
                          {order.pickup_address.country} {" - "}
                          {order.pickup_address.pincode}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="od-loading-container">
                <p>No data</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DonationDetails;
