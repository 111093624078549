import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./footer.css";
import {
  faEnvelope,
  faHome,
  faMessage,
  faPaperPlane,
  faPhone,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faSquareInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import ReactStars from "react-rating-stars-component";
import InputContext from "../../Auth/InputContext";
import Toast from "../../../Component/Toastify/Toast";
import loadingImgPath from "../../../Assets/rolling.svg";
import NewFeedBack from "../Feedback/NewFeedBack";

// import Alert
const APP_NAME = process.env.REACT_APP_NAME;
const APP_SLOGAN = process.env.REACT_APP_SLOGAN;
const BUYER_URL = process.env.REACT_APP_BUYER_DOMAIN;
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const COMPANY_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;
const SUPPORT_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const SUPPORT_PHONE = process.env.REACT_APP_ADMIN_PHONE;

const Footer = () => {
  const navigate = useNavigate();
  const [currYear, setCurrYear] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [ratingDataDetails, setRatingDataDetails] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    rating: "",
    environment: "Seller",
    is_anonymous: null,
  });
  const [isSubmitButtonDisable, setIsSubmitButtonDisable] = useState(false);
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setIsChecked(event.target.checked);
  };
  const [showNewFeedbackModal, setShowNewFeedbackModal] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({
    sellerWriteToUs: false,
    sellerFeedbackSurvey: false,
  });
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  useEffect(() => {
    const date = new Date();
    setCurrYear(date.getFullYear());
  }, []);
  useEffect(() => {
    const featureFlags = contextObj.inputValue.featureFlags;
    if (featureFlags) {
      setFeatureFlags((prev) => ({
        ...prev,
        sellerWriteToUs: featureFlags.seller_write_to_us,
        sellerFeedbackSurvey: featureFlags.seller_feedback_survey,
      }));
    }
  }, [contextObj.inputValue.featureFlags]);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const ratingChanged = (newRating) => {
    setRatingError("");
    setRatingDataDetails((prevData) => ({
      ...prevData,
      rating: newRating,
    }));
  };

  const closeNewFeedBack = () => {
    setShowNewFeedbackModal(false);
  };

  const handleCloseFeedback = () => {
    setShowFeedbackModal(false);
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setDescriptionError("");
    setRatingError("");
    setIsChecked(false);
    setRatingDataDetails((prevData) => ({
      ...prevData,
      name: "",
      phone: "",
      email: "",
      message: "",
      rating: "",
      environment: "Seller",
      is_anonymous: null,
    }));
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "email") {
      setEmailError("");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "message") {
      setDescriptionError("");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "phone") {
      setPhoneError("");
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      if (value.trim() !== "" || value == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));
      }
    } else if (name === "name") {
      setNameError("");
      if (/^[A-Za-z\s]+$/.test(value)) {
        if (value.trim() !== "" || value == "") {
          setRatingDataDetails((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
      if (value.trim() == "") {
        setRatingDataDetails((prevData) => ({
          ...prevData,
          [name]: value.trim(),
        }));
      }
    }
  };

  // Feedback submit
  const createFeedback = async () => {
    setIsSubmitButtonDisable(true);
    let errorHas = false;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (isChecked) {
      if (ratingDataDetails.name != "" && ratingDataDetails.name != null) {
        if (
          ratingDataDetails.name.length < 2 ||
          ratingDataDetails.name.length > 50
        ) {
          setNameError("Name should be between 2 and 50 characters.");
          errorHas = true;
        } else {
          setNameError("");
        }
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingDataDetails.phone);

      if (ratingDataDetails.phone !== "" && ratingDataDetails.phone !== null) {
        if (!isPhoneNumber) {
          setPhoneError("Please enter a valid Phone.");
          errorHas = true;
        } else {
          setPhoneError("");
        }
      }

      const isEmail = emailRegex.test(ratingDataDetails.email);
      if (ratingDataDetails.email !== "" && ratingDataDetails.email != null) {
        if (!isEmail) {
          setEmailError("Please enter a valid Email.");
          errorHas = true;
        } else if (
          ratingDataDetails.email.length < 5 ||
          ratingDataDetails.email.length > 100
        ) {
          setEmailError("Email should be between 3 and 150 characters.");
          errorHas = true;
        } else {
          setEmailError("");
        }
      }

      if (
        ratingDataDetails.message === "" ||
        ratingDataDetails.message === null
      ) {
        setDescriptionError("Description is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.message.length < 10 ||
        ratingDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingDataDetails.rating === "" ||
        ratingDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }
    } else {
      if (ratingDataDetails.name === "" || ratingDataDetails.name === null) {
        setNameError("Name is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.name.length < 2 ||
        ratingDataDetails.name.length > 50
      ) {
        setNameError("Name should be between 2 and 50 characters.");
        errorHas = true;
      } else {
        setNameError("");
      }
      const phoneRegex =
        /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
      const isPhoneNumber = phoneRegex.test(ratingDataDetails.phone);

      if (ratingDataDetails.phone === "" || ratingDataDetails.phone === null) {
        setPhoneError("Phone is required.");
        errorHas = true;
      } else if (!isPhoneNumber) {
        setPhoneError("Please enter a valid Phone.");
        errorHas = true;
      } else {
        setPhoneError("");
      }

      const isEmail = emailRegex.test(ratingDataDetails.email);
      if (ratingDataDetails.email === "" || ratingDataDetails.email === null) {
        setEmailError("Email is required.");
        errorHas = true;
      } else if (!isEmail) {
        setEmailError("Please enter a valid Email.");
        errorHas = true;
      } else if (
        ratingDataDetails.email.length < 5 ||
        ratingDataDetails.email.length > 100
      ) {
        setEmailError("Email should be between 3 and 150 characters.");
        errorHas = true;
      } else {
        setEmailError("");
      }

      if (
        ratingDataDetails.message === "" ||
        ratingDataDetails.message === null
      ) {
        setDescriptionError("Description is required.");
        errorHas = true;
      } else if (
        ratingDataDetails.message.length < 10 ||
        ratingDataDetails.message.length > 500
      ) {
        setDescriptionError(
          "Description should be between 10 and 500 characters."
        );
        errorHas = true;
      } else {
        setDescriptionError("");
      }

      if (
        ratingDataDetails.rating === "" ||
        ratingDataDetails.rating === null
      ) {
        setRatingError("Rating is required.");
        errorHas = true;
      }
    }
    if (errorHas == true) {
      setIsSubmitButtonDisable(false);
      return;
    }
    const payload = {};
    if (isChecked) {
      if (ratingDataDetails.name != "" && ratingDataDetails.name != null) {
        payload.name = ratingDataDetails.name;
      }
      if (ratingDataDetails.phone !== "" && ratingDataDetails.phone !== null) {
        payload.phone = ratingDataDetails.phone;
      }
      if (ratingDataDetails.email !== "" && ratingDataDetails.email != null) {
        payload.email = ratingDataDetails.email;
      }
      payload.is_anonymous = true;
      payload.message = ratingDataDetails.message;
      payload.rating = ratingDataDetails.rating;
      payload.environment = ratingDataDetails.environment;
    } else {
      payload.is_anonymous = false;
      payload.name = ratingDataDetails.name;
      payload.phone = ratingDataDetails.phone;
      payload.email = ratingDataDetails.email;
      payload.message = ratingDataDetails.message;
      payload.rating = ratingDataDetails.rating;
      payload.environment = ratingDataDetails.environment;
    }
    const response = await fetch(`${API_HOST}/api/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();
    setIsSubmitButtonDisable(false);
    if (res.status === 201) {
      showToast("success", "Message submitted successfully");
      handleCloseFeedback();
    } else if (res.status === 409) {
    } else if (res.status === 404) {
    } else if (res.status === 400) {
    } else if (res.status === 403) {
    }
  };

  // News Letter Submit
  const [emailValues, setEmailValues] = useState("");
  const [newsLetterLoading, setNewsLetterLoading] = useState(false);

  const isValidEmail = (email) => {
    const regex =
      /^[0-9|a-z|A-Z]+([\.-]?[0-9|a-z|A-Z]+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(
        email
      );
    return regex;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\s/g, "");
    setEmailValues(sanitizedValue);
  };

  const newsLetterSumbit = async () => {
    const payload = {
      email: emailValues,
    };

    setNewsLetterLoading(true);
    const response = await fetch(`${API_HOST}/api/newsletter`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const res = await response.json();

    if (res.status === 201) {
      showToast("success", "Thanks for subscribing!");
      setEmailValues("");
    } else if (res.status === 409) {
      showToast("info", "Email address is already subscribed.");
      setEmailValues("");
    }
    setNewsLetterLoading(false);
  };

  useEffect(() => {
    if (showNewFeedbackModal) {
      document.body.style.overflow = "hidden"; // Disable scroll when the modal is open
      return () => {
        document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
      };
    }
  }, [showNewFeedbackModal]);

  return (
    <>
      <section id="footer">
        <div className="footer-container">
          <div className="main-footer">
            <div className="logoinfo" data-aos="fade-up">
              <h2 className="appNameFooterPage">{APP_NAME}</h2>
              <p>{APP_SLOGAN}</p>
            </div>
            <div className="com" data-aos="fade-up">
              <ul>
                <li>
                  <a
                    tabIndex={0}
                    href="#pricing"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    tabIndex={0}
                    href="#faq-section"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    FAQ's
                  </a>
                </li>
                {featureFlags.sellerFeedbackSurvey && (
                  <li className="feedback_section">
                    <a
                      tabIndex={0}
                      onClick={() => {
                        setShowNewFeedbackModal(true);
                      }}
                    >
                      Feedback
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="com" data-aos="fade-up">
              <ul>
                <li>
                  <a
                    tabIndex={0}
                    href="#photography-guidelines"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Photography Guidelines
                  </a>
                </li>
                <li>
                  <a
                    tabIndex={0}
                    href="#packaging-guidelines"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Packaging Guidelines
                  </a>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <a
                    tabIndex={0}
                    onClick={() => {
                      window.open(BUYER_URL, "_blank");
                    }}
                  >
                    Shop Online on JOYREJOY
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-2nd-section">
            <div className="footer-2nd-section-container">
              <div className="footer-a-tag footer-address">
                <span>
                  <FontAwesomeIcon icon={faHome} />{" "}
                  <a
                    tabIndex={0}
                    href="https://maps.app.goo.gl/3k8zLqdmA8yNoULJ6"
                    target="_blank"
                  >
                    A-323, Siddhraj Zori,
                    <br />
                    Sargasan Cross Road,
                    <br />
                    Sargasan, Gandhinagar-382421,
                    <br />
                    Gujarat-INDIA
                  </a>
                </span>
              </div>
              <div className="footer-a-tag">
                <span>Let Us Connect</span>
                {featureFlags.sellerWriteToUs && (
                  <li
                    onClick={() => {
                      setShowFeedbackModal(true);
                    }}
                    style={{ marginTop: "7px" }}
                  >
                    <FontAwesomeIcon icon={faMessage} />
                    &nbsp;&nbsp;&nbsp;
                    <span tabIndex={0}>Write to us</span>
                  </li>
                )}
                <div className="footer-icons">
                  <a
                    href={`mailto:${SUPPORT_EMAIL}`}
                    tabIndex={0}
                    id="email-icon"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="footer-icon"
                      style={{ fontSize: "18px" }}
                    />
                  </a>
                  <a href={`tel:${SUPPORT_PHONE}`} tabIndex={0} id="phone-icon">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="footer-icon"
                      style={{ fontSize: "18px" }}
                    />
                  </a>
                  <a
                    tabIndex={0}
                    href="https://www.linkedin.com/company/joyrejoy/"
                    target="_blank"
                    id="linkedin-icon"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="footer-icon"
                    />
                  </a>
                  <a
                    tabIndex={0}
                    href="https://www.facebook.com/people/JoyRejoy/61558971548726/"
                    target="_blank"
                    id="facebook-icon"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="footer-icon"
                    />
                  </a>
                  <a
                    tabIndex={0}
                    href="https://www.instagram.com/joyrejoy__/"
                    target="_blank"
                    id="instagram-icon"
                  >
                    <FontAwesomeIcon
                      icon={faSquareInstagram}
                      className="footer-icon"
                      style={{ fontSize: "21px" }}
                    />
                  </a>
                </div>
              </div>

              <div>
                <p className="newsletter-para">
                  Subscribe news-letter to get latest update.
                </p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    newsLetterSumbit();
                  }}
                  className="newsletter-input-line"
                >
                  <input
                    autoComplete="off"
                    required
                    type="email"
                    name="email"
                    className="footer-newsletter-input"
                    placeholder="Enter email address"
                    value={emailValues}
                    onChange={handleInputChange}
                  />
                  <button
                    type="submit"
                    aria-label="newsletter-btn"
                    className="newsletter-btn"
                    disabled={newsLetterLoading}
                  >
                    {newsLetterLoading ? (
                      <img
                        src={loadingImgPath}
                        alt=""
                        className="news-letter-loading"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className="paper-plane"
                      />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-last-line">
          <div className="footer-copyright-container">
            <p className="footer-copyright">
              &copy; {currYear} {APP_NAME} by Mplify Tech Services Pvt Ltd. All
              Rights Reserved.
            </p>
            <p className="footer-policy-list">
              <li
                onClick={() => {
                  navigate("/privacy-policy");
                  handleScrollToTop();
                }}
              >
                Privacy Policy{" "}
              </li>
              <p>{" | "}</p>
              <li
                onClick={() => {
                  navigate("/cookie-policy");
                  handleScrollToTop();
                }}
              >
                Cookie Policy
              </li>
              <p>{" | "}</p>
              <li
                onClick={() => {
                  navigate("/terms-and-condition");
                  handleScrollToTop();
                }}
              >
                Terms And Conditions
              </li>
            </p>
          </div>
        </div>
      </section>
      {/* New Feedback modal */}
      {showNewFeedbackModal && (
        <NewFeedBack closeNewFeedBack={closeNewFeedBack} />
      )}

      {/* Feedback modal */}
      {showFeedbackModal && (
        <div className="Feedback_modal_container">
          <div className="Feedback_modal_c2">
            <div className="cross_button_container">
              <button className="cross_button" onClick={handleCloseFeedback}>
                <FontAwesomeIcon style={{ color: "#595959" }} icon={faX} />
              </button>
            </div>
            <h1 id="Feedback_Heading">Write To Us</h1>
            <div>
              <label className="login-label-tag">
                <input
                  type="text"
                  className="email-phone-input login_input_tag"
                  onChange={handleChange}
                  name="name"
                  placeholder={`Name ${isChecked ? "(optional)" : ""}`}
                  maxLength={50}
                  value={ratingDataDetails.name}
                />
                <span className="login-Span-tags">
                  {" "}
                  Name {isChecked ? "(optional)" : " "}
                </span>
              </label>
              <li className="fotter-error">{nameError}</li>
            </div>
            <div>
              <label className="login-label-tag">
                <input
                  type="text"
                  className="email-phone-input login_input_tag"
                  onChange={handleChange}
                  name="phone"
                  placeholder={`Phone ${isChecked ? "(optional)" : ""}`}
                  maxLength={10}
                  value={ratingDataDetails.phone}
                />
                <span className="login-Span-tags">
                  {" "}
                  Phone {isChecked ? "(optional)" : " "}
                </span>
              </label>
              <li className="fotter-error">{phoneError}</li>
            </div>
            <div>
              <label className="login-label-tag">
                <input
                  type="text"
                  className="email-phone-input login_input_tag"
                  onChange={handleChange}
                  name="email"
                  placeholder={`Email ${isChecked ? "(optional)" : ""}`}
                  maxLength={50}
                  value={ratingDataDetails.email}
                />
                <span className="login-Span-tags">
                  {" "}
                  Email {isChecked ? "(optional)" : " "}
                </span>
              </label>
              <li className="fotter-error">{emailError}</li>
            </div>
            <div>
              <label className="login-label-tag">
                <textarea
                  type="text"
                  className="email-phone-input login_input_tag footer-textarea"
                  onChange={handleChange}
                  maxLength={500}
                  minLength={10}
                  name="message"
                  placeholder="Message"
                  value={ratingDataDetails.message}
                />
                <span className="login-Span-tags"> Message</span>
              </label>
              <li className="fotter-error">{descriptionError}</li>
            </div>
            <div>
              <p className="rate_your_experience">
                How would you rate our website?
              </p>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={40}
                isHalf={false}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ff9f00"
              />
              <li className="fotter-error">{ratingError}</li>
            </div>
            <div className="ckeckbox-container">
              <label className="checkbox-label">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                {"  "}
                Send Anonymous
              </label>
            </div>
            <button
              onClick={createFeedback}
              className="submit-button"
              disabled={isSubmitButtonDisable}
            >
              {isSubmitButtonDisable ? (
                <img
                  src={loadingImgPath}
                  alt="Loading"
                  className="disabledBtn-Loading"
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
