import React from "react";
import { useState, useEffect, useContext } from "react";

import Footer from "../Home/Footer/Footer";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";

const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;

const RefundPolicy = () => {

 
  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{
      backgroundColor : "#fff"
    }}>
      <div
        className="body-container"      
      >
        <div className="policy-container" >
          <ResourcesSidebar activeResource={"Refund Policy"} />
          <div className="policy-content">
            <h1
              className={`policy-content-heading ${
                isSticky ? "sticky-heading" : ""
              }`}
            >
              Refund Policy
            </h1>
            <p>Returns</p>

            <p>
              <span>
                Our policy lasts 3 days. If 3 days have gone by since your
                purchase, unfortunately we can't offer you a refund or exchange.
              </span>
            </p>
            <ul>
              <li>
                To be eligible for a return, your item must be unused and in the
                same condition that you received it. It must also be in the
                original packaging.
              </li>
              <li>
                To complete your return, we require a receipt or proof of
                purchase.
              </li>
              <li>
                Please do not send your purchase back to the manufacturer.
              </li>
            </ul>
            <p>
              <span>
                There are certain situations where only partial refunds are
                granted:
              </span>
            </p>
            <ul>
              <li>If item received is different than the item ordered</li>
              <li>
                If condition of the item received is not matching the condition
                at which it was ordered
              </li>
              <li>Any item that is returned more than 3 days after delivery</li>
            </ul>
            <p>Refunds</p>
            <p>
              <span>
                Once your return is received and inspected, we will send you an
                email to notify you that we have received your returned item. We
                will also notify you of the approval or rejection of your
                refund.
              </span>
            </p>
            <p>
              <span>
                If you are approved, then your refund will be processed, and a
                credit will automatically be applied to your credit card or
                original method of payment, within a certain amount of days.
              </span>
            </p>
            <p>Late or missing refunds</p>
            <p>
              <span>
                If you haven't received a refund yet, first check your bank
                account again.
              </span>
              <span>
                Then contact your credit card company, it may take some time
                before your refund is officially posted.
              </span>
              <span>
                Next contact your bank. There is often some processing time
                before a refund is posted.
              </span>
            </p>
            <p>
              <span>
                If you've done all of this and you still have not received your
                refund yet, please contact us at{" "}
                <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a>
              </span>
            </p>
            <p>Exchanges</p>

            <p>
              <span>
                We only replace the products if they don't match what was agreed
                upon when the purchase was made. Please email us at 
                <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a> if you need
                to exchange it for another item. After that mail your product
                to: {ADMIN_ADDRESS}
              </span>
            </p>
            <p>Shipping</p>
            <p>
              <span>
                To return your product, you should mail your product to:{" "}
                {ADMIN_ADDRESS}
              </span>
            </p>
            <p>
              <span>
                You will be responsible for paying for your own shipping costs
                for returning your item. Shipping costs are non-refundable. If
                you receive a refund, the cost of return shipping will be
                deducted from your refund.
              </span>
            </p>
            <p>
              <span>
                Depending on where you live, the time it may take for your
                exchanged product to reach you, may vary.
              </span>
              <span>
                If you are shipping an item over INR 1000, you should consider
                using a trackable shipping service or purchasing shipping
                insurance. We don't guarantee that we will receive your returned
                item.
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
