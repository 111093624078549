import React, { useContext, useEffect, useState } from "react";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import loadingImgPath from "../../Assets/rolling.svg";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getDataFromCookies,
  setDataToCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import Toast from "../../Component/Toastify/Toast";
import InputContext from "../Auth/InputContext";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const API_KEY = process.env.REACT_APP_POSTOFFICE_API_KEY;

const ProfileAddress = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);

  const [streetAddressError, setStreetAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [isAddressDataChanged, setIsAddressDataChanged] = useState(false);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [isUnreachablePincode, setIsUnreachablePincode] = useState(false);
  const [addressEditMode, setAddressEditMode] = useState(false);
  const [pageLoadderForMobileScreen, setPageLoadderForMobileScreen] =
    useState(true);
  const [sellerStatus, setSellerStatus] = useState(false);

  const { showToast } = Toast();
  const [profileDataReadOnly, setProfileDataReadOnly] = useState({
    street_address: "",
    city: "",
    state: "",
    pincode: "",
    country: "India",
  });
  const [profileData, setProfileData] = useState({
    street_address: "",
    city: "",
    state: "",
    pincode: "",
    country: "India",
  });
  const [streetAddressName, setStreetAddressName] = useState("");
  const [pincodeName, setPincodeName] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  //image upload
  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });

  const toCamelCase = (text) => {
    const words = text.split(" ");
    const result = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return result.join(" ");
  };
  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };
  // get seller profile

  const fetchProfileData = async () => {
    const url = `${API_HOST}/${ApiEndpoint.SellersProfileApi}`;
    const your_access_token = getDataFromCookies("R3p7uLw9Xk");

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${your_access_token}`,
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setProfileData(res.data);
      setProfileDataReadOnly(res.data);
      setStreetAddressName(res.data.street_address);
      setPincodeName(res.data.pincode);
      setCityName(res.data.city);
      setStateName(res.data.state);
      setPageLoadderForMobileScreen(false);
      if (props.setIsPageLoading !== undefined) {
        props.setIsPageLoading(false);
      }
      if (res.data.image) {
        setContainer1({
          image: `${API_HOST + "/" + res.data.image}`,
        });
      }
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("R3p7uLw9Xk", "/");
      removeDataFromCookie("isLoggedIn", "/");
      removeDataFromCookie("fullName");
      removeDataFromCookie("firstChar");
      removeDataFromCookie("email");
      contextObj.setInput("isSignupNavigation", true);
      navigate(`${navigation.home}`);
      showToast("info", Alert.alertForLoginExpired);
    } else {
      console.error(res);
    }
  };

  // seller status
  const profileStatus = async () => {
    const your_access_token = getDataFromCookies("R3p7uLw9Xk");
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileStatusApi}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${your_access_token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const res = await response.json();
    if (res.status === 404) {
      setSellerStatus(true);
    }
  };

  useEffect(() => {
    fetchProfileData();
    profileStatus();
  }, []);

  // get city and state
  const getCityAndState = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.data.gov.in/resource/7eca2fa3-d6f5-444e-b3d6-faa441e35294?api-key=${API_KEY}&format=json&filters[pincode]=${pincode}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (pincode.length >= 0 && pincode.length === 6) {
        if (data.records.length === 0) {
          setPincodeLoading(false);
          setPincodeError("Apologies! We're unable to reach this address.");
          setIsUnreachablePincode(true);
          setProfileData((prevData) => ({
            ...prevData,
            city: "",
            state: "",
          }));
        } else {
          const pinCodeDetails = data.records;
          // let country = pinCodeDetails[0].Country;
          const state = toCamelCase(data.records[0]._statename);
          const city = toCamelCase(data.records[0].districtname);
          setPincodeLoading(false);
          setPincodeError("");
          setCityError("");
          setStateError("");
          setIsUnreachablePincode(false);
          setProfileData((prevData) => ({
            ...prevData,
            city: city,
            state: state,
          }));
        }
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    // button disabled and enabled by input events
    if (
      (name === "street_address" && value === streetAddressName) ||
      (name === "pincode" && value === pincodeName) ||
      (name === "city" && value === cityName) ||
      (name === "state" && value === stateName)
    ) {
      setIsAddressDataChanged(false);
    } else {
      setIsAddressDataChanged(true);
    }

    // update profiledata useState value while updating input fields
    if (name === "pincode") {
      setPincodeError("");
      if (value.trim().length == 6) {
        setPincodeLoading(true);
        getCityAndState(value.trim()); //fetch the city and state using pincode
      }

      setProfileData((prevData) => ({
        ...prevData,
        [name]: value.trim(),
      }));
    } else if (name === "city") {
      setCityError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "state") {
      setStateError("");
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "street_address") {
      setStreetAddressError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const url = `${API_HOST}/${ApiEndpoint.SellersProfileApi}`;
  // update product pickup location
  const productPickUpAddressSubmit = async (event) => {
    event.preventDefault();
    let errorHas = false;
    //if (isUnreachablePincode) return;

    //Sellers Address.

    if (
      profileData.street_address === "" ||
      profileData.street_address === null
    ) {
      setStreetAddressError(Alert.alertForAddress);
      errorHas = true;
    } else if (
      profileData.street_address.length < 3 ||
      profileData.street_address.length > 150
    ) {
      setStreetAddressError(Alert.alertForAddressLength);
      errorHas = true;
    } else {
      setStreetAddressError("");
    }

    //Pincode validation
    if (profileData.pincode === "") {
      setPincodeError(Alert.alertForPincode);
      errorHas = true;
    } else if (profileData.pincode.length < 6) {
      setPincodeError(Alert.alertForPincodeLength);
      errorHas = true;
    } else if (isUnreachablePincode == true) {
      setPincodeError("Apologies! We're unable to reach this address.");
      errorHas = true;
    } else {
      setPincodeError("");
    }

    //City validation
    if (profileData.city === "" || profileData.city === null) {
      setCityError(Alert.alertForCity);
      errorHas = true;
    } else if (profileData.city.length < 3 || profileData.city.length > 50) {
      setCityError(Alert.alertForCityLength);
      errorHas = true;
    } else {
      setCityError("");
    }

    //State validation
    if (profileData.state === "") {
      setStateError(Alert.alertForState);
      errorHas = true;
    } else {
      setStateError("");
    }

    if (errorHas === true) return;

    const formData = new FormData();
    formData.append("basic_info", 0);
    formData.append("address_info", 1);
    formData.append("payment_info", 0);
    formData.append("business_info", 0);

    if (profileData.street_address !== null) {
      formData.append("street_address", profileData.street_address.trim());
    }
    if (profileData.pincode !== null) {
      formData.append("pincode", profileData.pincode);
    }
    if (profileData.city !== null) {
      formData.append("city", profileData.city);
    }
    if (profileData.country !== null) {
      formData.append("country", profileData.country);
    }
    if (profileData.state !== null) {
      formData.append("state", profileData.state);
    }

    // as per PUT rules for  passing formData
    formData.append("_method", "PUT");
    const your_access_token = getDataFromCookies("R3p7uLw9Xk");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${your_access_token}`,
      },
      body: formData,
    });

    const res = await response.json();

    if (res.status === 200) {
      fetchProfileData();
      setProfileDataReadOnly(res.data);
      setStreetAddressName(profileData.street_address.trim());
      setPincodeName(profileData.pincode);
      setCityName(profileData.city);
      setStateName(profileData.state);
      // Set Profile Status
      if (res.data && res.data.status === 1) {
        props.profileUnverified(false);
        contextObj.setInput("isSellerVerified", "true");
      } else {
        props.profileUnverified(true);
        contextObj.setInput("isSellerVerified", "false");
      }

      setSellerStatus(true);
      setIsAddressDataChanged(false);
      showToast("success", Alert.alertForProfileUpdate);
      setAddressEditMode(false);

      if (res.data.pincode) {
        setDataToCookies("pincode", res.data.pincode, "/");
      }
    } else {
      console.error(res);
    }
  };

  return (
    <>
      {pageLoadderForMobileScreen === false ? (
        <div className="pickup-info">
          <div className="profile-content-heading">
            <h3 id="Profile-Address-heading-name">Pickup Address</h3>
            <button
              type="button"
              id="sellers-addressEditButton"
              className={`blue-edit-btn ${addressEditMode ? "hidden" : ""}`}
              onClick={() => setAddressEditMode(true)}
            >
              Edit
            </button>
          </div>

          {addressEditMode ? (
            <form onSubmit={productPickUpAddressSubmit}>
              <div className="pickup-input-address">
                <div>
                  <label>
                    Pincode <span className="red">*</span>
                  </label>
                  <div className="pincode-line">
                    <input
                      id="sellers-pincodeInputFeilds"
                      type="text"
                      name="pincode"
                      className="form-control"
                      onChange={handleChange}
                      value={profileData.pincode}
                      onKeyPress={handleKeyPress}
                      minLength={6}
                      maxLength={6}
                    />
                    {pincodeLoading && (
                      <img src={loadingImgPath} className="pincode-loading" />
                    )}
                  </div>
                  {pincodeError && (
                    <span className="profile-error-red">{pincodeError}</span>
                  )}
                </div>
                <div>
                  <label>
                    Address (e.g. House number, building name, road name, area,
                    colony) <span className="red">*</span>
                  </label>
                  <input
                    id="sellers-streetAddress"
                    type="text"
                    name="street_address"
                    className="form-control"
                    onChange={handleChange}
                    value={profileData.street_address}
                  />
                  {streetAddressError && (
                    <span className="profile-error-red">
                      {streetAddressError}
                    </span>
                  )}
                </div>
                <div className="MobileNotToShow"></div>

                <div>
                  <label>
                    City <span className="red">*</span>
                  </label>
                  <input
                    id="sellers-cityFeild"
                    type="text"
                    name="city"
                    className="form-control"
                    onChange={handleChange}
                    value={profileData.city}
                  />
                  {cityError && (
                    <span className="profile-error-red">{cityError}</span>
                  )}
                </div>
                <div className="input-row addressStateLable">
                  <label>
                    State <span className="red">*</span>
                  </label>
                  <select
                    id="sellers-stateFeilds"
                    name="state"
                    className="form-control"
                    value={profileData.state}
                    onChange={handleChange}
                  >
                    <option id="sellers-stateSelectionFeilds" disabled value="">
                      Select
                    </option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman & Nicobar Islands
                    </option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra & Nagar Haveli">
                      Dadra & Nagar Haveli
                    </option>
                    <option value="Daman and Diu">Daman & Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu & Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                  {stateError && (
                    <span className="profile-error-red">{stateError}</span>
                  )}
                </div>
              </div>
              <div className="profile-button-row">
                <button
                  id="sellers-updateAddressButton"
                  disabled={!isAddressDataChanged}
                  className="profile-btn"
                  style={{
                    opacity: isAddressDataChanged ? 1 : 0.5,
                    pointerEvents: isAddressDataChanged ? "" : "none",
                  }}
                >
                  Update
                </button>
                <button
                  type="button"
                  className="profile-btn cancel-btn"
                  onClick={() => {
                    setAddressEditMode(false);
                    setProfileData(profileDataReadOnly);
                    setIsAddressDataChanged(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <>
              <div className="profile-address-input-view">
                <div>
                  <p className="profile-label-view">Pincode</p>
                  <p className="profile-input-view">
                    {profileDataReadOnly.pincode}
                  </p>
                </div>
                <div>
                  <p className="profile-label-view">
                    Address (House number, building name, road name)
                  </p>
                  <p className="profile-input-view">
                    {profileDataReadOnly.street_address}
                  </p>
                </div>
                <div className="addressblankdiv"></div>
                <div>
                  <p className="profile-label-view">City</p>
                  <p className="profile-input-view">
                    {profileDataReadOnly.city}
                  </p>
                </div>
                <div>
                  <p className="profile-label-view">State</p>
                  <p className="profile-input-view">
                    {profileDataReadOnly.state}
                  </p>
                </div>
                <div className="addressblankdiv"></div>
              </div>
            </>
          )}
          <p className="profile-note">
            <span>Note: </span>
            We will use this address to manage pickup items from your location.
          </p>
        </div>
      ) : (
        <div className="profile-loading-container">
          <img className="loader" src={loadingImgPath} />
        </div>
      )}
    </>
  );
};

export default ProfileAddress;
