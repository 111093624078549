import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpRightFromSquare,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import navigation from "../../Configs/Navigation.json";
import Alert from "../../Configs/Alerts.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../Component/Toastify/Toast";
import validator from "validator";
import loadingImgPath from "../../Assets/rolling.svg";
import InputContext from "../Auth/InputContext";
import AiImage from "../../Assets/ai_icon.png";

const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_URL = process.env.REACT_APP_BUYER_DOMAIN;

let isShopNameChanged = false;
let isShopUrlChanged = false;
let isShopAddressChanged = false;
let isBusinessTypeChanged = false;
let isGSTNumberChanged = false;
let isShopContactEmailChanged = false;
let isShopContactNumberChanged = false;

const BusinessDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);

  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [businessEditMode, setBusinessEditMode] = useState(false);
  const [showCheckboxContainer, setShowCheckboxContainer] = useState(true);
  const [profileIsOwner, setProfileIsOwner] = useState(false);
  const [profileDataReadOnly, setProfileDataReadOnly] = useState({
    is_owner: 0,
    shop_name: "",
    shop_url: "",
    business_contact_email: "",
    business_contact_phone: "",
    business_type: "",
    gst_number: "",
    business_address: "",
  });
  const [profileData, setProfileData] = useState({
    is_owner: 0,
    shop_name: "",
    shop_url: "",
    business_contact_email: "",
    business_contact_phone: "",
    business_type: "",
    gst_number: "",
    business_address: "",
  });
  const [loadingShopUrl, setLoadingShopUrl] = useState(false);
  const [shopNameWrite, setShopNameWrite] = useState(false);
  const [shopUrlWrite, setShopUrlWrite] = useState(false);
  const [shopUrlAvailable, setShopUrlAvailable] = useState("");
  const [enableAiImage, setEnableAiImage] = useState(false);
  const [enableAvailableImage, setEnableAvailableImage] = useState(false);
  const [enableNotAvailableImage, setEnableNotAvailableImage] = useState(false);

  const [isBusinessDetailsChange, setIsBusinessDetailsChange] = useState(false);
  const [shopNameError, setShopNameError] = useState("");
  const [shopUrlError, setShopUrlError] = useState("");
  const [isDataLoading, setisDataLoading] = useState(true);
  const [businessContactEmailError, setBusinessContactEmailError] =
    useState("");
  const [businessContactPhoneError, setBusinessContactPhoneError] =
    useState("");
  const [businessTypeError, setBusinessTypeError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [businessAddressError, setBusinessAddressError] = useState("");
  const [shopName, setShopName] = useState("");
  const [shopUrl, setShopUrl] = useState("");
  const [businessContactEmail, setBusinessContactEmail] = useState("");
  const [businessContactPhone, setBusinessContactPhone] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setShowModal(!showModal);
  };
  const closeModal = () => {
    setShowModal(false);
    setIsChecked(false);
  };

  const token = getDataFromCookies("R3p7uLw9Xk");

  const fetchProfileData = async () => {
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProfileApi}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setProfileData(res.data);
      setProfileDataReadOnly(res.data);
      setProfileIsOwner(res.data.is_owner);
      setShopName(res.data.shop_name);
      setShopUrl(res.data.shop_url);
      setBusinessContactEmail(res.data.business_contact_email);
      setBusinessContactPhone(res.data.business_contact_phone);
      setBusinessType(res.data.business_type);
      setGstNumber(res.data.gst_number);
      setBusinessAddress(res.data.business_address);
      setisDataLoading(false);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("R3p7uLw9Xk", "/");
      removeDataFromCookie("isLoggedIn", "/");
      removeDataFromCookie("fullName");
      removeDataFromCookie("firstChar");
      removeDataFromCookie("email");
      contextObj.setInput("isSignupNavigation", true);
      showToast("info", Alert.alertForLoginExpired);
      navigate(`${navigation.home}`);
    } else {
      console.error(res);
    }
  };

  // get seller profile
  useEffect(() => {
    fetchProfileData();
  }, []);

  const generateShopURL = async () => {
    setLoadingShopUrl(true);
    setShopUrlWrite(false);
    const token = getDataFromCookies("R3p7uLw9Xk");
    const payload = {
      shop_name: profileData.shop_name,
    };
    const response = await fetch(`${API_HOST}/${ApiEndpoint.generateShopURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    const res = await response.json();

    if (res.status === 200) {
      setProfileData((prevData) => ({
        ...prevData,
        shop_url: res.data,
      }));
      setShopUrlAvailable(true);
    } else if (res.status === 401) {
      if (res.message === "You are not authorized.") {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("R3p7uLw9Xk", "/");
        removeDataFromCookie("isLoggedIn", "/");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
        contextObj.setInput("isSignupNavigation", true);
        navigate(`${navigation.home}`);
        showToast("info", Alert.alertForLoginExpired);
      } else if (res.message.includes("not verified")) {
        contextObj.setInput("isSellerVerified", "false");
      }
    }
    setLoadingShopUrl(false);
  };

  // handle generate shop_url api calls
  useEffect(() => {
    const debounce = setTimeout(() => {
      if (shopNameWrite && profileData.shop_name != "") {
        generateShopURL();
      }
    }, 1000);
    return () => {
      clearTimeout(debounce);
    };
  }, [profileData.shop_name]);

  const shopUrlavailability = async () => {
    setLoadingShopUrl(true);
    const token = getDataFromCookies("R3p7uLw9Xk");

    const payload = {
      shop_url: profileData.shop_url,
    };
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.shopUrlAvailability}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      setShopUrlAvailable(true);
      setShopUrlError(profileData.shop_url + " is available.");
    } else if (res.status === 404) {
      setShopUrlAvailable(false);
      setShopUrlError(profileData.shop_url + " already exists.");
    } else if (res.status === 401) {
      if (res.message === "You are not authorized.") {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("R3p7uLw9Xk", "/");
        removeDataFromCookie("isLoggedIn", "/");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email"); contextObj.setInput("isSignupNavigation", true);
        navigate(`${navigation.home}`);
        showToast("info", Alert.alertForLoginExpired);
      } else if (res.message.includes("not verified")) {
        contextObj.setInput("isSellerVerified", "false");
      }
    }
    setLoadingShopUrl(false);
  };

  // handle generate shop_url api calls
  useEffect(() => {
    const debounce = setTimeout(() => {
      if (
        shopUrlWrite &&
        profileData.shop_url != "" &&
        profileDataReadOnly.shop_url != profileData.shop_url &&
        isBusinessDetailsChange
      ) {
        shopUrlavailability();
      }
    }, 1000);
    return () => {
      clearTimeout(debounce);
    };
  }, [profileData.shop_url]);

  // get shop url suggestion
  const getShopUrlSuggestion = async () => {
    setLoadingShopUrl(true);
    setShopUrlWrite(false);
    setShopUrlError("");
    const token = getDataFromCookies("R3p7uLw9Xk");

    const payload = {
      shop_name: profileData.shop_name,
    };

    try {
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.shopUrlSuggesstion}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const res = await response.json();

      if (res.status === 200) {
        setProfileData((prevData) => ({
          ...prevData,
          shop_url: res.data,
        }));
        if (res.data != profileDataReadOnly.shop_url) {
          setIsBusinessDetailsChange(true);
        } else setIsBusinessDetailsChange(false);
      } else if (res.status === 401) {
        if (res.message === "You are not authorized.") {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("R3p7uLw9Xk", "/");
          removeDataFromCookie("isLoggedIn", "/");
          removeDataFromCookie("fullName");
          removeDataFromCookie("firstChar");
          removeDataFromCookie("email"); contextObj.setInput("isSignupNavigation", true);
          navigate(`${navigation.home}`);
          showToast("info", Alert.alertForLoginExpired);
        } else if (res.message.includes("not verified")) {
          contextObj.setInput("isSellerVerified", "false");
        }
      }
    } catch (error) {
      console.error("Shop URL Suggestions API failed to connect!");
    }

    setLoadingShopUrl(false);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    value = value.trimStart();
    if (name === "business_contact_phone") {
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      input.value = numericValue;
      setProfileData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));

      setBusinessContactPhoneError("");
      isShopContactNumberChanged = numericValue != businessContactPhone;
    } else if (name === "business_contact_email") {
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      isShopContactEmailChanged = value != businessContactEmail;
      setBusinessContactEmailError("");
    } else if (name === "gst_number") {
      if (value == "" || value != gstNumber) {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setGstNumberError("");
      }
      isGSTNumberChanged = value != gstNumber;
    } else if (name == "shop_name") {
      if (value != shopName || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setShopNameWrite(true);
        setShopNameError("");
        setShopUrlError("");
      }
      isShopNameChanged = value != shopName;
    } else if (name == "shop_url") {
      if (value != shopUrl || value == "") {
        value = value.trim();
        value = value.replace(/[^a-zA-Z0-9_-]/g, "");
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setShopUrlWrite(true);
        setShopUrlError("");
      }
      isShopUrlChanged = value != shopUrl;
    } else if (name === "business_address") {
      if (value == "" || value != businessAddress) {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));

        setBusinessAddressError("");
      }
      isShopAddressChanged = value != businessAddress;
    } else if (name === "business_type") {
      if (value.trim() !== "" || (value == "" && value != businessType)) {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));

        setBusinessTypeError("");
      }
      isBusinessTypeChanged = value != businessType;
    }

    setIsBusinessDetailsChange(
      isShopNameChanged ||
        isShopUrlChanged ||
        isShopAddressChanged ||
        isBusinessTypeChanged ||
        isGSTNumberChanged ||
        isShopContactEmailChanged ||
        isShopContactNumberChanged
    );
  };

  const Modal = (props) => {
    return (
      <div className="modal" style={{ display: props.show ? "block" : "none" }}>
        <div className="modal-content">
          <span className="close close-cross-button" onClick={props.onClose}>
            <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
          </span>
          <h1 className="Modal-heading">Are you sure?</h1>
          <p className="showShop-modal-popup-para">
            Please note that once you enable "I own a shop/company" then we
            might not be able to undo this action. Additionally, below mentioned
            details would be required to consider your profile as complete,
          </p>
          <li className="Shop-owner-list-popup">Shop Name</li>
          <li className="Shop-owner-list-popup">
            Business Contact Information (Email, Phone Number) (Verified)
          </li>
          <li className="Shop-owner-list-popup">
            Business Type (Individual, Company)
          </li>
          <li className="Shop-owner-list-popup">PAN/Tax ID (GST Number)</li>
          <li className="Shop-owner-list-popup">Business Address</li>
          <div className="shopOwner-button-row">
            <button
              type="button"
              id="Business_No_Let_me_continue_as-is_button"
              className="profile-btn cancel-btn"
              onClick={props.onClose}
            >
              No, Let me continue as-is
            </button>
            <button
              type="button"
              id="Business_Yes_I_got_it_button"
              className="profile-btn"
              onClick={() => {
                setShowCheckboxContainer(false);
                setBusinessEditMode(true);
                setShowModal(false);
                setProfileData((prevData) => ({
                  ...prevData,
                  is_owner: 1,
                }));
              }}
            >
              Yes, I got it
            </button>
          </div>
        </div>
      </div>
    );
  };

  const url = `${API_HOST}/${ApiEndpoint.SellersProfileApi}`;

  const BusinessDetailsSubmit = async () => {
    let errorHas = false;
    const isPhoneNumber = (phone) => {
      let regex = /^(\+?91[\-\s]?)?[6-9]\d{9}$/.test(phone);
      return regex;
    };
    const isValidGSTNumber = (num) => {
      let regex = /^[a-zA-Z0-9]+$/.test(num);
      return regex;
    };
    const isValidEmail = (newEmail) => {
      return validator.isEmail(newEmail);
    };
    if (profileData.shop_name === "" || profileData.shop_name === null) {
      setShopNameError(Alert.alertForShopName);
      errorHas = true;
    }
    if (profileData.shop_url === "" || profileData.shop_url === null) {
      setShopUrlError(Alert.alertForShopUrl);
      errorHas = true;
    } else if (
      profileData.shop_url !== profileDataReadOnly.shop_url &&
      !shopUrlAvailable
    ) {
      errorHas = true;
    }
    if (
      profileData.business_contact_email === "" ||
      profileData.business_contact_email === null
    ) {
      setBusinessContactEmailError(Alert.alertForBusinessContactEmail);
      errorHas = true;
    } else if (!isValidEmail(profileData.business_contact_email)) {
      setBusinessContactEmailError(Alert.alertForValidBusinessEmail);
      errorHas = true;
    }
    if (
      profileData.business_contact_phone === "" ||
      profileData.business_contact_phone === null
    ) {
      setBusinessContactPhoneError(Alert.alertForBusinessContactPhone);
      errorHas = true;
    } else if (!isPhoneNumber(profileData.business_contact_phone)) {
      setBusinessContactPhoneError(Alert.alertForValiedPhoneNumber);
      errorHas = true;
    }
    if (profileData.gst_number === "" || profileData.gst_number === null) {
      setGstNumberError(Alert.alertForPANOrTaxIDOrGSTNumber);
      errorHas = true;
    } else if (!isValidGSTNumber(profileData.gst_number)) {
      setGstNumberError(Alert.alertForPANOrTaxIDOrGSTNumberonlyallowed);
      errorHas = true;
    }
    if (
      profileData.business_address === "" ||
      profileData.business_address === null
    ) {
      setBusinessAddressError(Alert.alertForBusinessAddress);
      errorHas = true;
    }
    if (
      profileData.business_type === "" ||
      profileData.business_type === null
    ) {
      setBusinessTypeError(Alert.alertForBusinessType);
      errorHas = true;
    }
    // if any errors are there don't call the api
    if (errorHas === true) return;

    const formData = new FormData();
    formData.append("basic_info", 0);
    formData.append("address_info", 0);
    formData.append("payment_info", 0);
    formData.append("business_info", 1);
    formData.append("is_owner", profileData.is_owner);
    formData.append("shop_name", profileData.shop_name);
    formData.append("shop_url", profileData.shop_url);
    formData.append(
      "business_contact_email",
      profileData.business_contact_email
    );
    formData.append(
      "business_contact_phone",
      profileData.business_contact_phone
    );
    formData.append("gst_number", profileData.gst_number);
    formData.append("business_address", profileData.business_address);
    formData.append("business_type", profileData.business_type);

    formData.append("_method", "PUT");
    const token = getDataFromCookies("R3p7uLw9Xk");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const res = await response.json();

    if (res.status === 200) {
      // Set Profile Status
      if (res.data && res.data.status == 1) {
        props.profileUnverified(false);
        contextObj.setInput("isSellerVerified", "true");
      } else {
        props.profileUnverified(true);
        contextObj.setInput("isSellerVerified", "false");
      }

      // Set Business Owner Status
      if (res.data && res.data.is_owner == 1) {
        props.isBusinessOwners(false);
        contextObj.setInput("isBusinessOwner", true);
      } else {
        props.isBusinessOwners(true);
        contextObj.setInput("isBusinessOwner", false);
      }

      fetchProfileData();
      setBusinessEditMode(false);
      showToast("success", Alert.alertForProfileUpdate);

      setShopUrlError("");
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      console.error(errorMessage);
    } else {
      console.error(res.data);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"; // Disable scroll when the modal is open

      return () => {
        document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
      };
    }
  }, [showModal]);

  return (
    <>
      {!isDataLoading ? (
        <>
          <div className="shop-info">
            <div className="profile-content-heading">
              <h3 id="Profile-Business-heading-name">Business Details</h3>
              {profileData.is_owner == 1 && !businessEditMode && (
                <button
                  type="button"
                  id="seller-setEditBusiness-Button"
                  className={`blue-edit-btn`}
                  onClick={() => {
                    setBusinessEditMode(true);
                  }}
                >
                  Edit
                </button>
              )}
            </div>

            <div className="isChecked-shopOnwer-div">
              {showCheckboxContainer && profileData.is_owner == 0 && (
                <>
                  <input
                    type="checkbox"
                    id="my-Checkbox-business"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label className="isShop-owner-lable" htmlFor="myCheckbox">
                    I own a shop/company
                  </label>
                </>
              )}
              <Modal show={showModal} onClose={closeModal} />
            </div>
            <div>
              {businessEditMode ? (
                <>
                  <div className="bank-input">
                    <div>
                      <label>
                        Shop Name <span className="red">*</span>
                      </label>
                      <input
                        id="sellers-shop_name"
                        type="text"
                        name="shop_name"
                        className="form-control"
                        onChange={handleChange}
                        value={profileData.shop_name}
                      />
                      {shopNameError && (
                        <span className="profile-error-red">
                          {shopNameError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label>
                        Shop URL <span className="red">*</span>
                      </label>
                      <div className="shop-url-input-line">
                        <input
                          style={{ paddingRight: "35px" }}
                          id="sellers-shop_url"
                          type="text"
                          name="shop_url"
                          className="form-control"
                          value={profileData.shop_url}
                          onChange={handleChange}
                        />
                        {profileData.shop_name && (
                          <button
                            className="shop-url-suggestion-btn"
                            type="button"
                            title="Generate"
                            style={{
                              pointerEvents:
                                profileData.shop_name.length < 1 && "none",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                !loadingShopUrl &&
                                profileData.shop_name.length > 0
                              ) {
                                getShopUrlSuggestion();
                              }
                            }}
                          >
                            <img
                              src={AiImage}
                              alt="image"
                              className={`ai-image${
                                loadingShopUrl ? " ai-rotate" : ""
                              }`}
                            />
                          </button>
                        )}
                      </div>
                      {shopUrlError && (
                        <span
                          className="profile-error-red"
                          style={{ color: shopUrlAvailable && "green" }}
                        >
                          {shopUrlError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label>
                        PAN/Tax ID (GST Number) <span className="red">*</span>
                      </label>{" "}
                      <input
                        id="sellers-gst_number"
                        type="text"
                        name="gst_number"
                        className="form-control"
                        onChange={handleChange}
                        value={profileData.gst_number}
                      />
                      {gstNumberError && (
                        <span className="profile-error-red">
                          {gstNumberError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label>
                        Business Email <span className="red">*</span>
                      </label>
                      <input
                        id="sellers-business_contact_email"
                        type="text"
                        name="business_contact_email"
                        className="form-control"
                        onChange={handleChange}
                        value={profileData.business_contact_email}
                        minLength={0}
                        maxLength={60}
                      />
                      {businessContactEmailError && (
                        <span className="profile-error-red">
                          {businessContactEmailError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label>
                        Business Phone <span className="red">*</span>
                      </label>{" "}
                      <input
                        id="sellers-business_contact_phone"
                        type="text"
                        name="business_contact_phone"
                        className="form-control"
                        onChange={handleChange}
                        value={profileData.business_contact_phone}
                        maxLength={10}
                      />
                      {businessContactPhoneError && (
                        <span className="profile-error-red">
                          {businessContactPhoneError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label>
                        Business Address <span className="red">*</span>
                      </label>
                      <input
                        id="sellers-business_address"
                        type="text"
                        name="business_address"
                        className="form-control"
                        onChange={handleChange}
                        value={profileData.business_address}
                      />
                      {businessAddressError && (
                        <span className="profile-error-red">
                          {businessAddressError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label>
                        Business Type <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        onChange={handleChange}
                        name="business_type"
                        id="sellers-business_type"
                        value={profileData.business_type}
                      >
                        {" "}
                        <option value="">Select</option>
                        <option value="Individual">Individual</option>
                        <option value="Company">Company</option>
                      </select>
                      {businessTypeError && (
                        <span className="profile-error-red">
                          {businessTypeError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="profile-button-row">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        BusinessDetailsSubmit();
                      }}
                      id="sellers-Business-DetailsUpdateButton"
                      disabled={!isBusinessDetailsChange}
                      className="profile-btn"
                      style={{
                        opacity: isBusinessDetailsChange ? 1 : 0.5,
                        pointerEvents: isBusinessDetailsChange ? "" : "none",
                      }}
                    >
                      Update
                    </button>
                    {profileIsOwner == 1 && (
                      <button
                        type="button"
                        id="sellers-Business-DetailsCancelButton"
                        className="profile-btn cancel-btn"
                        onClick={() => {
                          setBusinessEditMode(false);
                          setBusinessAddressError("");
                          setShopNameError("");
                          setBusinessTypeError("");
                          setBusinessContactEmailError("");
                          setBusinessContactPhoneError("");
                          setGstNumberError("");
                          setProfileData((prevData) => ({
                            ...prevData,
                            shop_name: shopName,
                          }));
                          setProfileData((prevData) => ({
                            ...prevData,
                            business_contact_email: businessContactEmail,
                          }));
                          setProfileData((prevData) => ({
                            ...prevData,
                            business_contact_phone: businessContactPhone,
                          }));
                          setProfileData((prevData) => ({
                            ...prevData,
                            business_type: businessType,
                          }));
                          setProfileData((prevData) => ({
                            ...prevData,
                            gst_number: gstNumber,
                          }));
                          setProfileData((prevData) => ({
                            ...prevData,
                            business_address: businessAddress,
                          }));
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <div className="ProfileContainerside">
                  <div>
                    <p className="profile-label-view">Shop Name</p>
                    <p className="profile-input-view">
                      {profileDataReadOnly.shop_name &&
                        profileDataReadOnly.shop_name.trim()}
                    </p>
                  </div>
                  <div>
                    <p className="profile-label-view">Shop URL</p>
                    <p
                      className="profile-input-view shop-url-open"
                      onClick={() =>
                        window.open(
                          `${BUYER_URL}/shop/${profileDataReadOnly.shop_url}`,
                          "_blank"
                        )
                      }
                    >
                      {profileDataReadOnly.shop_url &&
                        profileDataReadOnly.shop_url.trim()}
                      {profileDataReadOnly.shop_url && (
                        <span className="shop-url-external-link">
                          <FontAwesomeIcon
                            icon={faUpRightFromSquare}
                            style={{ fontSize: "12px" }}
                          />
                        </span>
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="profile-label-view">Business Email</p>
                    <p className="profile-input-view">
                      {profileDataReadOnly.business_contact_email}
                    </p>
                  </div>

                  <div>
                    <p className="profile-label-view"> Business Phone</p>
                    <p className="profile-input-view">
                      {profileDataReadOnly.business_contact_phone}
                    </p>
                  </div>
                  <div>
                    <p className="profile-label-view">
                      PAN/Tax ID (GST Number)
                    </p>
                    <p className="profile-input-view">
                      {profileDataReadOnly.gst_number}
                    </p>
                  </div>
                  <div>
                    <p className="profile-label-view">Business Address</p>
                    <p className="profile-input-view">
                      {profileDataReadOnly.business_address}
                    </p>
                  </div>
                  <div>
                    <p className="profile-label-view">Business Type</p>
                    <p className="profile-input-view">
                      {profileDataReadOnly.business_type}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="profile-loading-container">
            <img className="loader" src={loadingImgPath} />
          </div>
        </>
      )}
    </>
  );
};

export default BusinessDetails;
