let generalFAQData = [
  {
    question: "What types of toys do you sell?",
    answer:
      "We sell a wide variety of preloved toys, including action figures, dolls, plush toys, puzzles, games, and more! We have toys for all ages and interests spreading over a wider price range.",
  },
  {
    question: "How are the toys priced?",
    answer:
      "The price of our toys is based on a variety of factors, such as the type of toy, the brand, the condition, and the current market value. We try to price our toys competitively so that you can find great deals on preloved toys.",
  },
  {
    question: "How do I buy a toy?",
    answer:
      "Buying a toy on our site is easy! Just browse our selection, find the toy you want, and add it to your cart. Then, proceed to the checkout and place your order. Once your order is placed, JOYREJOY will check for the current availability and condition of the product. If it looks at a customer satisfactory level then the interest is accepted and you will be prompted to enter your payment information. Once paid, the product will then undergo Packaging and Sanitizing and will be shipped to you at the earliest.",
  },
  {
    question: "What is your return policy?",
    answer: "Please refer Return Policy for further assistance.",
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept all major credit cards, as well as PayPal.",
  },
  {
    question: "How much does shipping cost?",
    answer:
      "Shipping costs vary depending on the size and weight of the toy, as well as your location. You can see the shipping cost for an item before you checkout.",
  },
  {
    question: "How long does it take to receive my order?",
    answer:
      "We ship orders within 1-2 business days. Once your order is shipped, you will receive a tracking number so you can follow its progress.",
  },
  {
    question: "Do you offer gift wrapping?",
    answer:
      "Currently, we do not offer any gift wrapping but yes if contacted Admin on a special need  basis, then we will try to provide a possible workaround.",
  },
  {
    question: "What if there are any damages during shipment ?",
    answer:
      "All our packaging is done as per the packaging guidelines of  the logistics company. If there are any transit damages to the box, kindly send us photos & videos before opening the package & we will address this there after.",
  },
  {
    question: "Is there any Guarantee or Warranty of the products ?",
    answer:
      "We do not take any guarantee or provide any warranty for the product.",
  },
  {
    question: "Is COD option available ?",
    answer:
      "No, we do not have any COD option available.  All payments need to be made on the website.",
  },
  {
    question: "How soon will my order reach me?",
    answer:
      "We try to ship products within 2-3 days of placing the order. The time taken to deliver the products to you depends on the location of delivery. Typically, metro cities will receive the products within 5-7 days of shipping the product and non-metros will receive the product in 7- 10 days of shipping.",
  },
  {
    question: "Can we cancel the order once placed?",
    answer:
      "You will receive an email with the update of your order. In addition to that, you can also view your order status from “My Orders” Page of your JOYREJOY Console.",
  },
];

const SafetyFAQData = [
  {
    question: "Are toys safe for children?",
    answer:
      "All of the toys we sell are safe for children to play with. However, we always recommend that you supervise young children when they are playing with toys.",
  },
  {
    question: "Do the toys contain any lead or other harmful chemicals?",
    answer:
      "We take the safety of our customers very seriously. All of the toys we sell meet or exceed all safety standards.",
  },
  {
    question: "What if a toy I purchased is recalled?",
    answer:
      "We will notify you immediately if a toy you purchased is recalled. You can then return the toy to us for a full refund.",
  },
];

const CustomerServiceData = [
  {
    question: "How can I contact you?",
    answer:
      "You can contact us by email or phone provided in the “Contact Us” section. We are always happy to help!",
  },
  {
    question: "What are your business hours?",
    answer: "Our customer service hours are Monday-Friday from 9am to 7pm.",
  },
];

const AdditionalFAQs = [
  {
    question: "What are the benefits of buying preloved toys?",
    answer:
      "There are many benefits to buying preloved toys! It's a great way to save money, be eco-friendly, and find unique toys that you wouldn't be able to find anywhere else.",
  },
  {
    question: "How can I be sure that the toys I buy are authentic?",
    answer:
      "At JOYREJOY, we are very much cautious about the quality and so we participate in the process of quality check twice*during the entire Product Shipment cycle)to ensure that the product being sold is in the same quality as it is currently shown in the images.",
  },
  {
    question: "What can I do with my old toys?",
    answer:
      "If you have old toys that you no longer want, you can sell them on our site! It's a great way to give your old toys a new life and make some extra money.",
  },
  {
    question: "Do you ship internationally?",
    answer:
      "As of now, we support shipping only within India. Please contact us for more information.",
  },
];

export { generalFAQData, SafetyFAQData, CustomerServiceData, AdditionalFAQs };
