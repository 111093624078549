import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import loadingImgPath from "../../Assets/rolling.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imagePlaceHolder from "../../Assets/image-placeholder.png";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Alert from "../../Configs/Alerts.json";
import InputContext from "../Auth/InputContext";
import Toast from "../../Component/Toastify/Toast";
import Paginate from "../../Component/Pagination/Paginate";
import InfiniteScroll from "react-infinite-scroll-component";
// import constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const Payments = () => {
  const location = useLocation();
  const { showToast } = Toast();
  const [scroll, setScroll] = useState(true);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sellerStatus, setSellerStatus] = useState("");
  const [isBusinessOwner, setIsBusinessOwner] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState([]);
  const [visibleIds, setVisibleIds] = useState([]);
  const contextObj = useContext(InputContext);
  const [totalPayments, setTotalPayments] = useState(0);
  let [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [lastPage, setLastPage] = useState(null);
  const [isInMobileView, setIsInMobileView] = useState(false);
  const [incompletePayment, setIncompletePayment] = useState(false);

  useEffect(() => {
    setIsInMobileView(window.innerWidth <= 600);
  }, []);
  const PaymentsPerAPIResponse = 10;
  let [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState("");
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    fetchData(data);
  };
  const token = getDataFromCookies("R3p7uLw9Xk");
  const toggleRow = useCallback(
    (rowId) => {
      setExpandedRows((prevExpandedRows) =>
        prevExpandedRows.includes(rowId)
          ? prevExpandedRows.filter((id) => id !== rowId)
          : [...prevExpandedRows, rowId]
      );
    },
    [setExpandedRows]
  );
  const isRowExpanded = useCallback(
    (rowId) => expandedRows.includes(rowId),
    [expandedRows]
  );

  // seller status
  let url = `${API_HOST}/${ApiEndpoint.SellersPaymentApi}?page_no=${currentPage}`;
  const fetchData = async (url) => {
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(navigation.home);
    }
    const response = await fetch(`${url}&per_page=${PaymentsPerAPIResponse}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      if (res.data.incomplete_payment) setIncompletePayment(true);
      const paymentData = res.data.payments;
      setScroll(paymentData.current_page < paymentData.last_page);
      if (paymentData.total) {
        setTotalPayments(paymentData.total);
      } else {
        setTotalPayments(0);
      }
      setPagination(paymentData);
      setLastPage(paymentData.last_page);
      setCurrentPage(paymentData.current_page);
      const dataArray = paymentData.data;
      let updatedData;
      if (window.innerWidth <= 600) {
        updatedData = [...data, ...dataArray];
      } else {
        setData([]);
        setFilteredData([]);
        updatedData = paymentData.data;
      }
      setData(updatedData);
      setFilteredData(updatedData);
      setSellerStatus(false);
      contextObj.setInput("isSellerVerified", "true");
    } else if (res.status === 404) {
      setSellerStatus(false);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("R3p7uLw9Xk", "/");
      removeDataFromCookie("isLoggedIn", "/");
      removeDataFromCookie("userName");
      removeDataFromCookie("firstChar");
      removeDataFromCookie("email");
      contextObj.setInput("isSignupNavigation", true);
      navigate(`${navigation.home}`);
      showToast("info", Alert.alertForLoginExpired);
      setSellerStatus(false);
    } else if (res.status === 401 && res.message.includes("not verified")) {
      setSellerStatus(true);
      contextObj.setInput("isSellerVerified", "false");
    }
    setLoading();
  };

  useEffect(() => {
    fetchData(url);
  }, []);

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
    if (contextObj.inputValue.isBusinessOwner === true) {
      setIsBusinessOwner(true);
    }
  }, [
    contextObj.inputValue.isSellerVerified,
    contextObj.inputValue.isBusinessOwner,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "data.id",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * PaymentsPerAPIResponse,
      },
      {
        Header: "",
        accessor: "product_details.thumb_image",
        Cell: ({ value }) => (
          <img
            id="sellers-productImage"
            src={`${IMG_HOST}/${value}`}
            loading="lazy"
          />
        ),
      },

      {
        Header: "Product",
        accessor: "product_details.name",
        cell: ({ value }) => (
          <div id="sellers-productName-headingDiv" title={value}>
            {value}
          </div>
        ),
      },

      {
        Header: "Amount",
        accessor: "payable_amount",
        Cell: ({ value }) => (
          <div id="sellers-productSellingPrice" className="cell-container">
            ₹ {value}
          </div>
        ),
      },

      {
        Header: "Timestamp",
        accessor: "created_at",
        Cell: ({ value }) => {
          const date = new Date(value);
          const options = {
            year: "numeric",
            month: "long", // Use 'short' for abbreviated month names
            day: "numeric",
          };
          const formattedDate = date.toLocaleString(undefined, options);
          const formattedTime = date.toLocaleTimeString();

          return (
            <div id="sellers-orderTimestamp">
              <div>{formattedDate}</div>
              <div>{formattedTime}</div>
            </div>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let backgroundColor;
          if (value === "Processing") {
            backgroundColor = "#ffe6bc";
          } else if (value === "Initiated") {
            backgroundColor = "#CCE6CC";
          } else if (value === "Completed") {
            backgroundColor = "#7CB342";
          } else if (value === "Cancelled") {
            backgroundColor = "#FECDCD";
          } else {
            backgroundColor = "#F0F0F0";
          }

          return (
            <p className="order-status" style={{ backgroundColor }}>
              {value}
            </p>
          );
        },
      },
    ],
    [currentPage]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPageIndex,
        pageSize: PaymentsPerAPIResponse,
        globalFilter: "",
      },

      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const StatusCell = ({ value }) => {
    let backgroundColor;
    let color;

    if (value === "Processing") {
      backgroundColor = "#ffe6bc";
    } else if (value === "Initiated") {
      backgroundColor = "#F0F0F0";
    } else if (value === "Completed") {
      backgroundColor = "#7CB342";
    } else if (value === "Cancelled") {
      backgroundColor = "#FECDCD";
    }

    return (
      <p
        title="Status"
        className="product-status"
        style={{ backgroundColor, color, whiteSpace: "nowrap" }}
      >
        {value}
      </p>
    );
  };

  const handleSearchformobile = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts = data.filter(
      (Product) =>
        Product.product.name.toLowerCase().includes(term.toLowerCase()) ||
        Product.order_status.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredData(filteredProducts);
  };

  const toggleDivVisibility = (paymentId) => {
    setVisibleIds((prevIds) => {
      if (prevIds.includes(paymentId)) {
        return prevIds.filter((id) => id !== paymentId);
      } else {
        return [...prevIds, paymentId];
      }
    });
  };

  const isDivVisible = (paymentId) => {
    return visibleIds.includes(paymentId);
  };

  return (
    <div className="full-container">
      <div className="container">
        {sellerStatus && !isBusinessOwner && (
          <div className="seller-verification-line">
            <p>
              Your account verification is pending. To proceed, please{" "}
              <span
                className="complete-your-profile"
                onClick={() => {
                  navigate(`${navigation.profile}`);
                }}
              >
                ensure your Profile Information and Pickup Address is complete
              </span>{" "}
              and wait for the admin verification call.
            </p>
          </div>
        )}
        {sellerStatus && isBusinessOwner && (
          <div className="seller-verification-line">
            <p>
              Your account verification is pending. To proceed, please{" "}
              <span
                className="complete-your-profile"
                onClick={() => {
                  navigate(`${navigation.profile}`);
                }}
              >
                ensure your Profile Information, Payment Information, Pickup
                Address and Business Details is complete
              </span>{" "}
              and wait for the admin verification call.
            </p>
          </div>
        )}
        {incompletePayment && (
          <div className="seller-verification-line">
            <p>
              Your payment details is incomplete, To ensure successful money
              transfers, kindly complete your{" "}
              <span
                className="complete-your-profile"
                onClick={() => {
                  navigate(`${navigation.profile}`);
                }}
              >
                payment information
              </span>{" "}
              in your profile.
            </p>
          </div>
        )}
        <div className="container-heading">
          <h2>Payments</h2>
        </div>
        <div className="InnerTableContainer">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => (
                    <th>
                      <div
                        id={`sellersTableHeader_${index}`}
                        className="header-cell"
                        style={{
                          display: "flex",
                        }}
                      >
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                  <th></th>
                </tr>
              ))}
            </thead>

            <tbody
              {...getTableBodyProps()}
              style={{ backgroundColor: "#FFFFFF" }}
            >
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length + 1}>
                    <div className="loadingIconDivForProductPage">
                      <img
                        className="PageLoadingImageMobileForProduct"
                        src={loadingImgPath}
                      />
                    </div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                <>
                  {page.map((row, index) => {
                    prepareRow(row);

                    // Log the entire row.original object
                    const expandedContent = isRowExpanded(row.id) ? (
                      <tr id="justCross">
                        <td colSpan={row.cells.length + 1}>
                          <div className="additionalPaymentsDetails">
                            <div className="additionalPaymentsDetailsInnerDiv">
                              <p className="paymentSummaryText">Summary</p>
                              <div className="costCalculationDiv">
                                <p>
                                  Product Price:{" "}
                                  <span
                                    id={`sellers-paymentPrice_${index}`}
                                    style={{
                                      color: "#585858",
                                      fontWeight: "500",
                                    }}
                                  >
                                    ₹{row.original.amount}
                                  </span>
                                </p>
                                <p>
                                  Shipping Cost:{" "}
                                  <span
                                    id={`sellers-shippingCost${index}`}
                                    style={{
                                      color: "#FF5656",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {row.original.shipping_cost > 0 ? (
                                      <>₹{row.original.shipping_cost}</>
                                    ) : (
                                      <>
                                        ₹{row.original.shipping_cost}{" "}
                                        <span style={{ color: "green" }}>
                                          (Free)
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </p>
                                <hr className="dashLine" />
                              </div>
                              <p
                                id={`sellers-origionalPrice${index}`}
                                className="costCalculationPara"
                              >
                                Amount: ₹{row.original.payable_amount}
                              </p>
                            </div>
                            <div className="additionalPaymentsDetailsInnerDiv">
                              <p className="paymentSummaryText">From</p>
                              <div className="costCalculationDiv">
                                <p className="bankName">
                                  {row.original.from.account_holder_name}
                                </p>
                                <p>
                                  {row.original.from.bank_name}
                                  <br />
                                  {row.original.from.ifsc_code}
                                  <br />
                                  {row.original.from.branch_name}
                                  <br />
                                  {row.original.from.account_number}
                                  <br />
                                  {row.original.from.upi_id}
                                </p>
                              </div>
                            </div>
                            <div className="additionalPaymentsDetailsInnerDiv">
                              <p className="paymentSummaryText">To</p>
                              <div className="costCalculationDiv">
                                <p className="bankName">
                                  {row.original.to.account_holder_name}
                                </p>
                                <p>
                                  {row.original.to.bank_name}
                                  <br />
                                  {row.original.to.ifsc_code}
                                  <br />
                                  {row.original.to.branch_name}
                                  <br />
                                  {row.original.to.account_number}
                                  <br />
                                  {row.original.to.upi_id}
                                </p>
                              </div>
                            </div>
                            <div className="additionalPaymentsDetailsInnerDiv">
                              <p className="paymentSummaryText">Remark</p>
                              <p> {row.original.remarks}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : null;

                    return (
                      <React.Fragment key={row.id}>
                        <tr className="TableBodyData" {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                          <td>
                            <button
                              id="sellers-showMoreButton-forPayments"
                              className="ShowDetailsButton"
                              onClick={() => toggleRow(row.id)}
                            >
                              {isRowExpanded(row.id) ? (
                                <span className="detailsTextOfPaymentPage">
                                  Show Less
                                  <FontAwesomeIcon
                                    className="angleIcon"
                                    icon={faAngleUp}
                                  />
                                </span>
                              ) : (
                                <span className="detailsTextOfPaymentPage">
                                  More Details
                                  <FontAwesomeIcon
                                    className="angleIcon"
                                    icon={faAngleDown}
                                  />
                                </span>
                              )}
                            </button>
                          </td>
                        </tr>
                        {expandedContent}
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length + 1}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* table pagination */}

        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Seller Payments"}
            />
          )}
        </div>
        {/* from here the code is written for the mobile view */}
        <div className="productContainerForMobileView">
          {isInMobileView && (
            <div className="">
              <InfiniteScroll
                dataLength={filteredData.length}
                next={() => {
                  if (currentPage !== lastPage) {
                    currentPage = currentPage + 1;
                    fetchData(
                      `${API_HOST}/${ApiEndpoint.SellersPaymentApi}?page_no=${currentPage}`
                    );
                  }
                }}
                hasMore={scroll}
              >
                {loading ? (
                  <div className="loadingIconDivForProductPage">
                    <img className="loader" src={loadingImgPath} />
                  </div>
                ) : filteredData.length === 0 ? (
                  <div className="productInnerContainerForMobileView">
                    <p className="NoDataFoundDiv">No data</p>
                  </div>
                ) : (
                  <>
                    {filteredData &&
                      filteredData.map((Product, index) => (
                        <div key={index}>
                          <div className="productInnerContainerForMobileView">
                            <div className="imageDivForMobile">
                              <div className="imageInnerDivForMobile">
                                <img
                                  className="imageViewForMobile"
                                  src={
                                    Product.product_details.thumb_image
                                      ? IMG_HOST +
                                        "/" +
                                        Product.product_details.thumb_image
                                      : imagePlaceHolder
                                  }
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div className="productDetailsForMobile">
                              <div className="productNameDivForDonations">
                                {Product.product_details.name.length > 50 ? (
                                  <p>
                                    {Product.product_details.name.substring(
                                      0,
                                      50
                                    )}
                                    ...
                                  </p>
                                ) : (
                                  Product.product_details.name
                                )}
                              </div>

                              <div>
                                <p className="spanTagOfProductList">
                                  <span className="prductPriceInnerSpan">
                                    ₹{Product.product_details.product_price}
                                  </span>
                                </p>
                              </div>
                              <div className="statusOfProductDiv">
                                <p>
                                  <StatusCell value={Product.status} />
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                toggleDivVisibility(Product.id);
                              }}
                              className="arrowButtonForNextpage"
                            >
                              <FontAwesomeIcon
                                className="orderPageFontAwsomeIcon"
                                icon={
                                  isDivVisible(Product.id)
                                    ? faAngleUp
                                    : faAngleDown
                                }
                              />
                            </div>
                          </div>

                          {isDivVisible(Product.id) && (
                            <div className="paymentDetailsOfSellerDiv">
                              <hr className="payementMobileHrLine" />
                              <div className="mobileDivForPayment">
                                <p className="paymentSummaryText">Summary</p>
                                <div className="costCalculationDiv">
                                  <p>
                                    Product Price:{" "}
                                    <span
                                      style={{
                                        color: "#585858",
                                        fontWeight: "500",
                                      }}
                                    >
                                      ₹{Product.product_details.product_price}
                                    </span>
                                  </p>
                                  <p>
                                    Shipping Cost:{" "}
                                    <span
                                      style={{
                                        color: "#FF5656",
                                        fontWeight: "500",
                                      }}
                                    >
                                      - ₹{Product.shipping_cost}
                                    </span>
                                  </p>
                                  <hr className="dashLine" />
                                </div>
                                <p className="costCalculationPara">
                                  Amount: ₹{Product.payable_amount}
                                </p>
                              </div>
                              <div className="mobileDivForPayment1">
                                <p className="paymentSummaryText">From</p>
                                <div className="costCalculationDiv">
                                  <p className="bankName">
                                    <span className="payementHolderName">
                                      {Product.from.account_holder_name}
                                    </span>
                                  </p>
                                  <p>
                                    {Product.from.bank_name}
                                    <br />
                                    {Product.from.ifsc_code}
                                    <br />
                                    {Product.from.branch_name}
                                    <br />
                                    {Product.from.account_number}
                                    <br />
                                    {Product.from.upi_id}
                                  </p>
                                </div>
                              </div>
                              <div className="mobileDivForPayment">
                                <p className="paymentSummaryText1">To</p>
                                <div className="costCalculationDiv">
                                  <p className="bankName">
                                    <span className="payementHolderName">
                                      {Product.to.account_holder_name}
                                    </span>
                                  </p>
                                  <p>
                                    {Product.to.bank_name}
                                    <br />
                                    {Product.to.ifsc_code}
                                    <br />
                                    {Product.to.branch_name}
                                    <br />
                                    {Product.to.account_number}
                                    <br />
                                    {Product.to.upi_id}
                                  </p>
                                </div>
                                <div className="mobileDivForPayment">
                                  <p className="paymentSummaryText3">Remark</p>
                                  <div className="costCalculationDiv">
                                    <p className="mobile-remark-text">
                                      {" "}
                                      {Product.remarks}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    <div>
                      {scroll && (
                        <div className="loading-button-order-div">
                          <p className="loading-button-order-table-mobile">
                            Loading...
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payments;
