import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import loadingImgPath from "../../Assets/rolling.svg";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import InputContext from "../Auth/InputContext";
import Toast from "../../Component/Toastify/Toast";
import imagePlaceHolder from "../../Assets/image-placeholder.png";
import "./donation.css";

//constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const NGOs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ngos, setNgos] = useState([]);
  const [sellerStatus, setSellerStatus] = useState(false);
  const [isBusinessOwner, setIsBusinessOwner] = useState(false);
  const [loading, setLoading] = useState(true);
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();

  // fetch all ngos
  useEffect(() => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${navigation.home}`);
      showToast("info", Alert.alertForLoginExpired);
      return;
    }

    const fetchNgos = async () => {
      const response = await fetch(`${API_HOST}/${ApiEndpoint.SellersNgoApi}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        setNgos(res.data.data);
        setSellerStatus(false);
        contextObj.setInput("isSellerVerified", "true");
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("R3p7uLw9Xk", "/");
        removeDataFromCookie("isLoggedIn", "/");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
        contextObj.setInput("isSignupNavigation", true);
        navigate(`${navigation.home}`);
        showToast("info", Alert.alertForLoginExpired);
      } else if (res.status === 401 && res.message.includes("not verified")) {
        setSellerStatus(true);
        contextObj.setInput("isSellerVerified", "false");
      }
      setLoading(false);
    };
    fetchNgos();
  }, []);

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isBusinessOwner === true) {
      setIsBusinessOwner(true);
    }
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
  }, [
    contextObj.inputValue.isSellerVerified,
    contextObj.inputValue.isBusinessOwner,
  ]);

  return (
    <div className="container">
      {sellerStatus && !isBusinessOwner && (
        <div className="seller-verification-line">
          <p>
            Your account verification is pending. To proceed, please{" "}
            <span
              className="complete-your-profile"
              onClick={() => {
                navigate(`${navigation.profile}`);
              }}
            >
              ensure your Profile Information and Pickup Address is complete
            </span>{" "}
            and wait for the admin verification call.
          </p>
        </div>
      )}
      {sellerStatus && isBusinessOwner && (
        <div className="seller-verification-line">
          <p>
            Your account verification is pending. To proceed, please{" "}
            <span
              className="complete-your-profile"
              onClick={() => {
                navigate(`${navigation.profile}`);
              }}
            >
              ensure your Profile Information, Payment Information, Pickup
              Address and Business Details is complete
            </span>{" "}
            and wait for the admin verification call.
          </p>
        </div>
      )}
      <div className="container-heading">
        <h2>NGOs</h2>
      </div>
      <div className="donation-body">
        <div className="ngos">
          {loading ? (
            <div className="MobileImageContainerForDonations">
              <img className="loader" src={loadingImgPath} />
            </div>
          ) : (
            <>
              {ngos && ngos.length > 0 ? (
                ngos.map((ngo, index) => (
                  <div className="ngo-card" key={index}>
                    <div>
                      <div className="donation-row">
                        <div className="ngo-image-container">
                          <img
                            id="sellers-ngoImage"
                            className="ngo-profile-image"
                            src={
                              ngo.image
                                ? `${IMG_HOST}/${ngo.image}`
                                : imagePlaceHolder
                            }
                          />
                        </div>
                        <div>
                          <h3 id="sellers-ngoTitle" className="ngo-titile">
                            {ngo.org_name}
                          </h3>
                          <p className="ngo-tag-line">
                            Bringing hope, changing lives.
                          </p>
                          <p>
                            <span className="bold">Email:</span>{" "}
                            <a
                              id="sellers-ngoEamailAndPhone"
                              className="ngo-phone"
                              href={`mailto:${ngo.org_email}`}
                            >
                              {ngo.org_email}
                            </a>
                          </p>
                          <p>
                            <span className="bold">Phone:</span>{" "}
                            <a
                              id="sellers-ngoEamailAndPhone"
                              className="ngo-email"
                              href={`tel:${ngo.org_phone}`}
                            >
                              {ngo.org_phone}
                            </a>
                          </p>
                        </div>
                      </div>
                      <p className="margin-top-25">
                        <span className="bold">Address:</span>{" "}
                        <span
                          id="sellers-ngoAddress-forDonations"
                          className="ngoAddress-Title"
                        >
                          {ngo.address1}
                        </span>
                      </p>
                    </div>
                    <button
                      id="sellers-donateButton-forNgos"
                      onClick={() => {
                        navigate(`${navigation.ngoDonate}/${ngo.id}`);
                      }}
                      className="donation-btn"
                    >
                      Donate
                    </button>
                  </div>
                ))
              ) : (
                <div>
                  <p className="noNgoFoundPara">No data</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default NGOs;
