import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// import files
import InputContext from "../InputContext";
import { setDataToCookiesWithExpirationTime } from "../../../Component/Cookies/Cookie";
import navigation from "../../../Configs/Navigation.json";
import ApiEndpoint from "../../../Configs/APIEndpoints.json";
import Toast from "../../../Component/Toastify/Toast";
import ButtonImg from "../../../Assets/rolling.svg";
import "./signup.css";

import seller_sign_up from "../../../Assets/SignUpSellerRevised.jpg";
// constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Signup = () => {
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [validMobileNumber, SetValidMobileNumber] = useState("");
  const [isSendMobileOtp, setIsSendMobileOtp] = useState(false);
  const [IsValidOtp, setIsValidOtp] = useState(false);
  const [IsValidEmail, setIsValidEmail] = useState(false);
  const [otpVerifyToken, setOtpVerifyToken] = useState("");
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const [mobileOtpButtonDisable, setMobileOtpButtonDisable] = useState(false);
  const [signupData, setSignupData] = useState({
    phone: "",
    otp: "",
    email: "",
    name: "",
    password: "",
  });
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [errorForPhone, setErrorForPhone] = useState("");
  const [errorForOtp, SetErrorForOtp] = useState("");
  const [errorForEmail, setErrorForEmail] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [isActiveSecond, setIsActiveSecond] = useState(false);
  const [secondsContinue, setSecondsContinue] = useState(60);
  const [sendOtpDisable, SetSendOtpDisable] = useState(false);
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  // Input Handle For Validation Mobile Number
  const handleMobileNumberInput = (event) => {
    let { name, value } = event.target;
    if (name === "phone") {
      const trimmedValue = value.replace(/[^0-9]/g, "");
      if (trimmedValue.length === 10 && /^[6789]\d{9}$/.test(trimmedValue)) {
        setIsValidPhone(true);
      } else {
        setIsValidPhone(false);
      }
      if (
        trimmedValue.length === 10 &&
        /^[6789]\d{9}$/.test(trimmedValue) === false
      ) {
        setErrorForPhone("Enter Valid Mobile Number");
      } else {
        setErrorForPhone("");
      }
      setSignupData((prevData) => ({
        ...prevData,
        [name]: trimmedValue,
      }));
    }
  };

  // UseEfeect to set the email or phone value
  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();

    // login from home page
    const fromHomePage = searchParams.get("from") === "home";
    let mobile, email;
    let input = sessionStorage.getItem("home_input");
    if (input && input.length == 10) {
      mobile = input;
    } else {
      email = input;
    }
    if (fromHomePage && input.length === 10) {
      setSignupData((prevData) => ({
        ...prevData,
        phone: mobile,
      }));
      setIsValidPhone(true);
    } else {
      setSignupData((prevData) => ({
        ...prevData,
        email: email,
      }));
      setIsValidEmail(true);
    }
  }, [contextObj]);

  // Formated Time Zone
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const formatTimesecond = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Start Timer
  const startTimer = () => {
    setIsActive(true);
  };

  // Reset Timer
  const resetTimer = () => {
    setIsActive(false);
    setSeconds(60);
  };

  const resetTimersecond = () => {
    setIsActiveSecond(false);
    setSecondsContinue(60);
  };

  // Resend otp timer setting
  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  // Resend otp timer setting
  useEffect(() => {
    let interval = null;
    if (isActiveSecond && secondsContinue > 0) {
      interval = setInterval(() => {
        setSecondsContinue(secondsContinue - 1);
      }, 1000);
    }

    if (secondsContinue === 0) {
      resetTimersecond();
    }
    return () => clearInterval(interval);
  }, [isActiveSecond, secondsContinue]);
  // To implement an API for sending an OTP during the signup process.

  const signuphandleSendOtp = async () => {
    const Payload = {};
    Payload.phone = signupData.phone;
    SetValidMobileNumber(signupData.phone);
    const PayloadParam = JSON.stringify(Payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerAuthRegisterMobileSendOtp}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: PayloadParam,
      }
    );
    const res = await response.json();
    setMobileOtpButtonDisable(false);
    if (res.status === 201) {
      setIsActive(true);
      SetSendOtpDisable(true);
      startTimer();
      setOtpVerifyToken(res.data);
      setIsSendMobileOtp(true);
      setMobileOtpButtonDisable(false);
    } else if (res.status === 400) {
      setMobileOtpButtonDisable(false);
      setErrorForPhone("The mobile number has already been taken.");
    }
  };

  // To implement an API for Re-sending an OTP during the signup process.

  const signuphandleReSendOtp = async () => {
    const Payload = {};
    Payload.phone = signupData.phone;
    Payload.otp_verify_token = otpVerifyToken;
    SetValidMobileNumber(signupData.phone);
    const PayloadParam = JSON.stringify(Payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerAuthRegisterMobileResendOtp}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: PayloadParam,
      }
    );
    const res = await response.json();
    setMobileOtpButtonDisable(false);
    if (res.status === 201) {
      setIsActive(true);
      SetSendOtpDisable(true);
      startTimer();
      setOtpVerifyToken(res.data);
      setMobileOtpButtonDisable(false);
    } else if (res.status === 400) {
      setErrorForPhone(res.errors.phone);
      if (res.errors.phone == "The phone doesn't match with previous number.") {
        setShowRefreshButton(true);
      }
      setMobileOtpButtonDisable(false);
    }
    setMobileOtpButtonDisable(false);
  };

  // Input Hendle For Validation email and Otp

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "otp") {
      SetErrorForOtp("");
      const trimmedValue = value.replace(/[^0-9]/g, "");
      setSignupData((prevData) => ({
        ...prevData,
        [name]: trimmedValue,
      }));
      if (trimmedValue.length === 4 && /^\d+$/.test(trimmedValue)) {
        setIsValidOtp(true);
      } else {
        setIsValidOtp(false);
      }
    }
    setDisableContinueButton(false);
  };

  // API Integration For Validate Mobile Otp and send verification Mail to email.
  const createSellerAccount = async () => {
    const payload = {};
    payload.phone = signupData.phone;
    payload.otp_verify_token = otpVerifyToken;
    payload.otp = signupData.otp;
    // payload.email = signupData.email;

    const PayloadParam = JSON.stringify(payload);
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellerAuthRegisterCreateAccountSuccesfull}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: PayloadParam,
      }
    );
    const res = await response.json();
    if (res.status === 201) {
      showToast("success", res.message);
      const expiryDate = new Date();
      setSubmitButtonDisable(false);
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      setDataToCookiesWithExpirationTime(
        "R3p7uLw9Xk",
        res.data,
        expiryDate,
        "/"
      );
      setDataToCookiesWithExpirationTime("isLoggedIn", true, expiryDate, "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "logInPage");
      // Delay navigation by 10 seconds
      setTimeout(() => {
        navigate(`${navigation.dashbord}`);
      }, 3000);
    } else if (res.status === 401) {
      setDisableContinueButton(false);
      SetErrorForOtp(res.message);
    } else if (res.status === 400) {
      if (res.errors.email) {
        setErrorForEmail(res.errors.email[0]);
        setDisableContinueButton(false);
      }
      if (res.errors.otp_verify_token) {
        SetErrorForOtp(res.errors.otp_verify_token[0]);
        setDisableContinueButton(false);
      }
    } else if (res.errors.otp_verify_token) {
      SetErrorForOtp(res.errors.otp_verify_token[0]);
      setDisableContinueButton(false);
    }
  };

  const handleKeyPress = (event) => {
    if (
      signupData.phone.length === 10 &&
      /^[6789]\d{9}$/.test(signupData.phone)
    ) {
      if (event.key === "Enter") {
        {
          sendOtpDisable === false
            ? signuphandleSendOtp()
            : signuphandleReSendOtp();
        }
      }
    }
  };

  return (
    <>
      <div className="SignUp_Container">
        <div className="signup_container_C1">
          <img
            src={seller_sign_up}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="signup_container_C2">
          <div className="signup_container_c2_c1">
            <h1 className="signup_Joyrejoy_heading">JOYREJOY</h1>
            <div className="signup_JoyRejoy_TextContainer">
              <p className="signup_joyRejoy_para">Welcome</p>
              <li className="signup_Joyrejoy_lsit">
                {" "}
                Create your account to start selling
              </li>
            </div>

            <div>
              {isActive && (
                <div className="Signup_OtpSend_c1">
                  <li className="Signup_OtpSend_list">
                    We have sent an OTP to your mobile number:{" "}
                    {validMobileNumber}
                  </li>
                </div>
              )}
              <div className="signup_Joyrejoy_Phone_container">
                <label className="signup_Joyrejoy_lable_tag">
                  <input
                    id="sellers-enterMobile-forSignUp"
                    type="tel"
                    placeholder="Enter mobile number"
                    className="signup_Joyrejoy_input_tag"
                    name="phone"
                    autoComplete="off"
                    maxLength={10}
                    value={signupData.phone}
                    onChange={handleMobileNumberInput}
                    onKeyPress={handleKeyPress}
                    disabled={isActive}
                  />
                  <span className="signup_Joyrejoy_span_tag">
                    Enter mobile number
                  </span>
                </label>

                <>
                  {isValidPhone === true ? (
                    <>
                      {sendOtpDisable === false ? (
                        <>
                          <button
                            id="sellers-otpButton-forSignup"
                            className="signup_Joyrejoy_SendOtpButtonactive"
                            onClick={() => {
                              signuphandleSendOtp();
                              setMobileOtpButtonDisable(true);
                            }}
                            disabled={mobileOtpButtonDisable}
                          >
                            {mobileOtpButtonDisable ? (
                              <img
                                className="Signup_button_loading_Img"
                                src={ButtonImg}
                                alt=""
                              />
                            ) : (
                              "Send OTP"
                            )}
                          </button>
                        </>
                      ) : (
                        <>
                          {isActive === false ? (
                            <>
                              <button
                                id="sellers-ResendOtpButton-forSignup"
                                className="signup_Joyrejoy_SendOtpButtonactive"
                                onClick={() => {
                                  signuphandleReSendOtp();
                                  setMobileOtpButtonDisable(true);
                                }}
                                disabled={mobileOtpButtonDisable}
                              >
                                {mobileOtpButtonDisable ? (
                                  <img
                                    className="Signup_button_loading_Img"
                                    src={ButtonImg}
                                    alt=""
                                  />
                                ) : (
                                  "Resend OTP"
                                )}
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                id="sellers-ResendOtpButton-forSignup"
                                className="signup_Joyrejoy_SendOtpButton_disable"
                              >
                                Resend in {formatTime(seconds)}
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {sendOtpDisable === false ? (
                        <button
                          id="sellers-otpButton-forSignup"
                          className="signup_Joyrejoy_SendOtpButton_disable"
                        >
                          Send OTP
                        </button>
                      ) : (
                        <>
                          <button
                            id="sellers-ResendOtpButton-forSignup"
                            className="signup_Joyrejoy_SendOtpButton_disable"
                          >
                            Resend OTP
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
              <li className="Signup_error_Message">{errorForPhone}</li>

              <label className="signup_Joyrejoy_lable_tag">
                <input
                  id="sellers-enterOtp-forSignup"
                  type="text"
                  placeholder="Enter OTP"
                  className="signup_Joyrejoy_input_tag"
                  maxLength={4}
                  name="otp"
                  autoComplete="off"
                  onChange={handleChange}
                  value={signupData.otp}
                />
                <span className="signup_Joyrejoy_span_tag">Enter OTP</span>
              </label>
              <li className="Signup_error_Message">{errorForOtp}</li>

              {showRefreshButton && (
                <div className="refresh-div">
                  <button
                    className="link-button"
                    onClick={() => window.location.reload()}
                  >
                    Click Here To Start Again
                  </button>
                </div>
              )}

              <div className="signup_Button_Container">
                {isValidPhone == true &&
                IsValidOtp == true &&
                isSendMobileOtp == true ? (
                  //IsValidEmail == true
                  <>
                    <button
                      id="sellers-continueFor-RegisterButton"
                      className="signup_Continue_Button_active"
                      onClick={() => {
                        createSellerAccount();
                        setDisableContinueButton(true);
                      }}
                      disabled={disableContinueButton}
                    >
                      {disableContinueButton ? (
                        <img
                          className="Signup_button_loading_Img"
                          src={ButtonImg}
                          alt=""
                        />
                      ) : (
                        "Create Account"
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      id="sellers-continueFor-RegisterButton"
                      className="signup_Continue_Button_disable"
                    >
                      Create Account
                    </button>
                  </>
                )}
              </div>
              <div className="TermsConditionsTextHolder">
                {" "}
                <span>
                  <p className="Terms_Conditions_Text">
                    By continuing, I agree to{" "}
                    <span
                      onClick={() => {
                        navigate("/terms-of-use");
                      }}
                      className="openPolicyDocs"
                    >
                      Terms of Use
                    </span>{" "}
                    &{" "}
                    <span
                      onClick={() => {
                        navigate("/privacy-policy");
                      }}
                      className="openPolicyDocs"
                    >
                      Privacy Policy
                    </span>
                  </p>
                </span>
              </div>

              <div>
                <div className="loginsignupbuttoncontainer">
                  <div className="HrLineForLine"></div>
                  <li
                    id="seller-alreadyHaving-AccountButton"
                    className="Signup_Login_Link_Text"
                  >
                    Already have an account?
                  </li>
                  <div className="HrLineForLine"></div>
                </div>
                <button
                  id="sellers-continueTo-LoginButton"
                  className="Login-signup-quick-line-button"
                  onClick={() => {
                    navigate("/");
                    contextObj.setInput("isSignupNavigation", true);
                  }}
                >
                  Log in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
