import React from "react";
import "./serviceUnavailable.css";

function ServiceUnavailable(props) {
  return (
    <div className="serviceUnavailableDivContainer">
      <div className="serviceUnavailablePageContainer">
        <p className="serviceUnavailableP">
          {/* {Constants.HeadlinesMessages.messageForServiceUnavailable_First} */}
          Oops!! Service is currently unavailable.
        </p>
        <p className="serviceUnavailableP serviceUnavailablePageDescriptionTxt-second">
          {/* {Constants.HeadlinesMessages.messageForServiceUnavailable_Second} */}
          Please try again after sometime.
        </p>
      </div>
    </div>
  );
}
export default ServiceUnavailable;
