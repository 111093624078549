import React, { useState, useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";

const SalesGraph = (props) => {
  const [salesData, setSalesData] = useState([]);
  const [numbers, setNumbers] = useState([1, 2, 3, 4]);
  const [months] = useState(["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"]);
  const [quarters] = useState([1, 2, 3, 4]);

  useEffect(() => {
    setSalesData(props.data);
  }, [props]);

  useEffect(() => {
    if (quarters && salesData) {
      const updatedNumbers = quarters.map((quarter) => {
        const monthData = salesData.find((data) => data.quarter === quarter);
        return monthData ? monthData.total : 0;
      });
      setNumbers(updatedNumbers);
    }
  }, [quarters, salesData]);

  return (
    <div id="graph-container">
      <LineChart
        className="raw"
        xAxis={[
          {
            scaleType: "band",
            data: months,
          },
        ]}
        series={[{ data: numbers, area: true, color: "#02B2AF" }]}
      />
    </div>
  );
};

export default SalesGraph;
