import React from "react";

const DonationStatus = ({ value }) => {
  let backgroundColor;
  let color;
  let text;

  if (value === "Pending") {
    backgroundColor = "#FFF3CD";
    text = "Pending";
  } else if (value === "Confirmed") {
    backgroundColor = "#D4EDDA";
    text = "Confirmed";
  } else if (value === "Seller Pickup Processing") {
    backgroundColor = "#FFF3CD";
    text = "Seller Pickup Processing";
  } else if (value === "Seller Return Processing") {
    backgroundColor = "#F8D7DA";
    text = "Seller Return Processing";
  } else if (value === "Seller Return Completed") {
    backgroundColor = "#F8D7DA";
    text = "Seller Return Completed";
  } else if (value === "Cancelled") {
    backgroundColor = "#F8D7DA";
    text = "Cancelled";
  } else {
    backgroundColor = "#E2E3E5";
    text = value;
  }

  return (
    <p
      className="order-status"
      style={{
        backgroundColor,
        color,
      }}
    >
      {text}
    </p>
  );
};

export default DonationStatus;
