import React, { useEffect, useState, useContext } from "react";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import { useNavigate, useLocation } from "react-router-dom";
import loadingImgPath from "../../Assets/rolling.svg";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import Toast from "../../Component/Toastify/Toast";
import InputContext from "../Auth/InputContext";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const ProfilePayment = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);

  const [upiIdError, setUpiIdError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [accountHolderNameError, setAccountHolderNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [isBankDataChanged, setIsBankDataChanged] = useState(false);
  const [PaymentEditMode, setPaymentEditMode] = useState(false);
  const [upiIdName, setUPIIdName] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [ifscCodeName, setIfscCodeName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [pageLoadderForMobileScreen, setPageLoadderForMobileScreen] =
    useState(true);
  const { showToast } = Toast();
  const [BankNameList, setBankNameList] = useState([]);
  const [profileDataReadyOnly, setProfileDataReadOnly] = useState({
    upi_id: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    ifsc_code: "",
    account_holder_name: "",
  });
  const [profileData, setProfileData] = useState({
    upi_id: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    ifsc_code: "",
    account_holder_name: "",
  });

  //image upload
  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });

  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  // Get Bank Name
  const GetBankListName = async () => {
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.GetListOfAllBank}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setBankNameList(res.data);
    }
  };
  // get seller profile
  useEffect(() => {
    const url = `${API_HOST}/${ApiEndpoint.SellersProfileApi}`;
    const token = getDataFromCookies("R3p7uLw9Xk");

    const fetchProfileData = async () => {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        setProfileData(res.data);
        setProfileDataReadOnly(res.data);
        setUPIIdName(res.data.upi_id);
        setBankName(res.data.bank_name);
        setBranchName(res.data.branch_name);
        setIfscCodeName(res.data.ifsc_code);
        setAccountHolderName(res.data.account_holder_name);
        setAccountNumber(res.data.account_number);
        setPageLoadderForMobileScreen(false);
        if (props.setIsPageLoading !== undefined) {
          props.setIsPageLoading(false);
        }
        if (res.data.image) {
          setContainer1({
            image: `${API_HOST + "/" + res.data.image}`,
          });
        }
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("R3p7uLw9Xk", "/");
        removeDataFromCookie("isLoggedIn", "/");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
        contextObj.setInput("isSignupNavigation", true);
        showToast("info", Alert.alertForLoginExpired);
        navigate(`${navigation.home}`);
      } else {
        console.error(res);
      }
    };
    fetchProfileData();
    GetBankListName();
  }, []);

  const handleChange = (event) => {
    let { name, value } = event.target;
    setPaymentError("");
    if (
      (name === "upi_id" && value === upiIdName) ||
      (name === "bank_name" && value === bankName) ||
      (name === "branch_name" && value === branchName) ||
      (name === "ifsc_code" && value === ifscCodeName) ||
      (name === "account_holder_name" && value === accountHolderName) ||
      (name === "account_number" && value === accountNumber)
    ) {
      setIsBankDataChanged(false);
    } else {
      setIsBankDataChanged(true);
    }

    // update profiledata useState value while updating input fields
    if (name === "bank_name") {
      setBankNameError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "branch_name") {
      setBranchNameError("");
      const trimmedValue = value.replace(/\s{2,}/g, " ");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: trimmedValue,
        }));
      }
    } else if (name === "ifsc_code") {
      setIfscCodeError("");
      const upperCaseValue = value.toUpperCase();
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: upperCaseValue,
        }));
      }
    } else if (name === "account_number") {
      setAccountNumberError("");
      const trimmedValue = value.replace(/\D/g, "");
      const limitedValue = trimmedValue.slice(0, 34);

      setProfileData((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
    } else if (name === "account_holder_name") {
      value = event.target.value;
      setAccountHolderNameError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const url = `${API_HOST}/${ApiEndpoint.SellersProfileApi}`;
  // update Payment information
  const bankInformationSubmit = async () => {
    let errorHas = false;

    setUpiIdError("");

    if (profileData.bank_name === "" || profileData.bank_name === null) {
      setBankNameError(Alert.alertForBankName);
      errorHas = true;
    }

    //IFSC Code validation

    if (!profileData.ifsc_code) {
      setIfscCodeError(Alert.alertForIFSCcode);
      errorHas = true;
    } else if (profileData.ifsc_code && profileData.ifsc_code.length !== 11) {
      setIfscCodeError(Alert.alertForIFSClength);
      errorHas = true;
    } else {
      setIfscCodeError("");
    }

    // Branch Name validation
    if (!profileData.branch_name) {
      setBranchNameError(Alert.alertForBranchName);
      errorHas = true;
    } else if (
      profileData.branch_name &&
      (profileData.branch_name.length < 3 ||
        profileData.branch_name.length > 100)
    ) {
      setBranchNameError(Alert.alertForMaxBranchLength);
      errorHas = true;
    } else {
      setBranchNameError("");
    }

    //Account Holder Name

    if (!profileData.account_holder_name) {
      setAccountHolderNameError(Alert.alertForAccountHolder);
      errorHas = true;
    } else if (
      profileData.account_holder_name &&
      (profileData.account_holder_name.length < 3 ||
        profileData.branch_name.length > 60)
    ) {
      setAccountHolderNameError(Alert.alertForLengthOfAccountHolder);
      errorHas = true;
    } else {
      setAccountHolderNameError("");
    }

    // Account Number

    if (!profileData.account_number) {
      setAccountNumberError(Alert.alertForAccountNumber);
      errorHas = true;
    } else if (
      profileData.account_number &&
      (profileData.account_number.length < 6 ||
        profileData.account_number.length > 34)
    ) {
      setAccountNumberError(Alert.alertForLengthOfAccountNo);
      errorHas = true;
    } else {
      setAccountNumberError("");
    }

    const formData = new FormData();
    formData.append("basic_info", 0);
    formData.append("address_info", 0);
    formData.append("payment_info", 1);
    formData.append("business_info", 0);

    if (profileData.upi_id) {
      formData.append("upi_id", profileData.upi_id);
    }

    if (
      profileData.bank_name &&
      profileData.branch_name &&
      profileData.ifsc_code &&
      profileData.account_holder_name &&
      profileData.account_number
    ) {
      formData.append("bank_name", profileData.bank_name);
      formData.append("branch_name", profileData.branch_name);
      formData.append("ifsc_code", profileData.ifsc_code);
      formData.append("account_holder_name", profileData.account_holder_name);
      formData.append("account_number", profileData.account_number);
    }

    // if any errors are there don't call the api
    if (errorHas === true) return;

    // as per PUT rules for  passing formData
    formData.append("_method", "PUT");
    const token = getDataFromCookies("R3p7uLw9Xk");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const res = await response.json();

    if (res.status === 200) {
      // Set Profile Status
      if (res.data && res.data.status === 1) {
        props.profileUnverified(false);
        contextObj.setInput("isSellerVerified", "true");
      } else {
        props.profileUnverified(true);
        contextObj.setInput("isSellerVerified", "false");
      }
      setProfileDataReadOnly(res.data);
      setIsBankDataChanged(false);
      showToast("success", Alert.alertForProfileUpdate);
      setPaymentEditMode(false);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      console.error(errorMessage);
    } else {
      console.error(res.data);
    }
  };

  return (
    <>
      {pageLoadderForMobileScreen === false ? (
        <>
          <div className="bank-info">
            <div className="profile-content-heading">
              <h3 id="Profile-Payment-heading-name">Payment Information</h3>
              <button
                id="sellers-paymentEditButton"
                className={`blue-edit-btn ${PaymentEditMode ? "hidden" : ""}`}
                onClick={() => setPaymentEditMode(true)}
              >
                Edit
              </button>
            </div>

            {PaymentEditMode ? (
              <>
                <div className="bank-input">
                  <div>
                    <label>
                      Bank name <span className="red">*</span>
                    </label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="bank_name"
                      id="sellers-payment-Bank_Name"
                      value={profileData.bank_name}
                    >
                      {" "}
                      <option value="">Select</option>
                      {BankNameList.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {bankNameError && (
                      <span className="profile-error-red">{bankNameError}</span>
                    )}
                  </div>
                  <div>
                    <label>IFSC code</label>
                    <input
                      id="sellers-Ifsc_Code"
                      type="text"
                      name="ifsc_code"
                      className="form-control"
                      onChange={handleChange}
                      value={profileData.ifsc_code}
                    />
                    {ifscCodeError && (
                      <span className="profile-error-red">{ifscCodeError}</span>
                    )}
                  </div>
                  <div>
                    <label>Branch name</label>
                    <input
                      id="sellers-bankBranchName"
                      type="text"
                      name="branch_name"
                      className="form-control"
                      onChange={handleChange}
                      value={profileData.branch_name}
                      minLength={0}
                      maxLength={60}
                    />
                    {branchNameError && (
                      <span className="profile-error-red">
                        {branchNameError}
                      </span>
                    )}
                  </div>

                  <div>
                    <label>Account number</label>{" "}
                    <input
                      id="sellers-bankAccountNumber"
                      type="text"
                      name="account_number"
                      className="form-control"
                      onChange={handleChange}
                      value={profileData.account_number}
                      onKeyPress={handleKeyPress}
                    />
                    {accountNumberError && (
                      <span className="profile-error-red">
                        {accountNumberError}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Account holder name</label>{" "}
                    <input
                      id="sellers-accountHolderName"
                      type="text"
                      name="account_holder_name"
                      className="form-control"
                      onChange={handleChange}
                      value={profileData.account_holder_name}
                    />
                    {accountHolderNameError && (
                      <span className="profile-error-red">
                        {accountHolderNameError}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>UPI id (Optional)</label>
                    <input
                      id="sellers-Upi_idFeild"
                      type="text"
                      name="upi_id"
                      className="form-control"
                      onChange={handleChange}
                      value={profileData.upi_id}
                      minLength={0}
                      maxLength={60}
                    />
                    {upiIdError && (
                      <span className="profile-error-red">{upiIdError}</span>
                    )}
                  </div>
                </div>
                {paymentError && (
                  <p className="profile-error-red payment-error-line">
                    {paymentError}
                  </p>
                )}
                <div className="profile-button-row">
                  <button
                    onClick={bankInformationSubmit}
                    id="sellers-BankDetailsUpdateButton"
                    disabled={!isBankDataChanged}
                    className="profile-btn"
                    style={{
                      opacity: isBankDataChanged ? 1 : 0.5,
                      pointerEvents: isBankDataChanged ? "" : "none",
                    }}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="profile-btn cancel-btn"
                    onClick={() => {
                      setPaymentEditMode(false);
                      setProfileData(profileDataReadyOnly);
                      setIsBankDataChanged(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <div className="ProfileContainerside">
                <div>
                  <p className="profile-label-view">Bank name</p>
                  <p className="profile-input-view">
                    {profileDataReadyOnly.bank_name &&
                      profileDataReadyOnly.bank_name.trim()}
                  </p>
                </div>
                <div>
                  <p className="profile-label-view">IFSC code</p>
                  <p className="profile-input-view">
                    {profileDataReadyOnly.ifsc_code}
                  </p>
                </div>
                <div>
                  <p className="profile-label-view">Branch name</p>
                  <p className="profile-input-view">
                    {profileDataReadyOnly.branch_name}
                  </p>
                </div>
                <div>
                  <p className="profile-label-view">Account number</p>
                  <p className="profile-input-view">
                    {profileDataReadyOnly.account_number}
                  </p>
                </div>
                <div>
                  <p className="profile-label-view">Account holder name</p>
                  <p className="profile-input-view">
                    {profileDataReadyOnly.account_holder_name}
                  </p>
                </div>
                <div>
                  <p className="profile-label-view">UPI id (Optional)</p>
                  <p className="profile-input-view">
                    {profileDataReadyOnly.upi_id}
                  </p>
                </div>
              </div>
            )}
            <p className="profile-note">
              <span>Note: </span> We will use this payment information to
              transfer successful order amount.
            </p>
          </div>
        </>
      ) : (
        <div className="profile-loading-container">
          <img className="loader" src={loadingImgPath} />
        </div>
      )}
    </>
  );
};
export default ProfilePayment;
