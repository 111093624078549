import PageNotFound from "../../Assets/PageNotFound.jpg";
import { useNavigate } from "react-router-dom";
import "./route.css";

const RouteNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="pageNot_Found_main_Container">
        <div className="pageNotFoundContainer">
          <h1 className="Page_NoteFound_Opps">Oops !! Sorry </h1>
          <h1 className="Page_NotFound_Heading">
            This page is like a unicorn: it's mythical and doesn't really exist.
          </h1>
          <h1 className="Page_NotFound_Heading">
            But that doesn't mean you can't have fun looking for it!
          </h1>
          <div className="page_notfound_img_Container">
            <img className="notfound_image" src={PageNotFound} alt="" />
          </div>
          <div className="pagenotfound_Button_container">
            <button
              className="page_Notfound_Button"
              onClick={() => {
                navigate("/");
              }}
            >
              {" "}
              Go Back To Home Screen{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RouteNotFound;
