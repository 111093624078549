import { useState, useEffect,  } from "react";
import React from "react";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";
import Footer from "../Home/Footer/Footer";

const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const APP_NAME = process.env.REACT_APP_NAME;

const CookiePolicy = () => { 
  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{
      backgroundColor : "#fff"
    }}>
      <div
        className="body-container"
        
      >
        <div className="policy-container" >
          <ResourcesSidebar activeResource={"Cookie Policy"} />
          <div className="policy-content">
            <h1
              className={`policy-content-heading ${
                isSticky ? "sticky-heading" : ""
              }`}
            >
              Cookie Policy
            </h1>
            <p>
              <span>
                This Cookie Policy ("Policy") describes how {APP_NAME}{" "}
                ("Company", "we", "us", or "our") uses cookies and similar
                technologies ("Cookies") on our website and associated services
                (collectively referred to as the "Platform").
              </span>
            </p>
            <p>
              What are Cookies?
              <span>
                Cookies are small text files that are placed on your device
                (computer, phone, tablet) when you visit a website. They allow
                the website to remember your actions and preferences (such as
                login, language, font size) over a period of time, so you don't
                have to keep re-entering them whenever you come back to the site
                or browse from one page to another.
              </span>
            </p>
            <p>
              Types of Cookies We Use
              <span>
                We use various types of Cookies on the platform to serve
                different purposes. Here are some of the main types:
              </span>
            </p>
            <ul>
              <li>
                Essential Cookies: These Cookies are strictly necessary for the
                platform to function properly. They enable you to navigate the
                platform and use its features, such as accessing secure areas
                and remembering shopping cart items. Without these Cookies, some
                or all of the platform's functionality may not work as intended.
              </li>
              <li>
                Performance Cookies: These Cookies collect information about how
                you use the platform, such as the pages you visit and the links
                you click. They help us understand how the platform is
                performing and identify areas for improvement. This information
                is anonymous and does not identify you individually.
              </li>
              <li>
                Functionality Cookies: These Cookies allow us to remember your
                preferences and settings on the platform, such as your preferred
                language and location. They also enable us to provide you with
                personalized features, like product recommendations based on
                your browsing history.
              </li>
            </ul>
            <p>
              How We Use Cookies
              <span>We use Cookies for various purposes, including:</span>
            </p>
            <ul>
              <li>To personalize your experience on the platform</li>
              <li>To improve the performance of the platform</li>
              <li>To analyze how you use the platform</li>
              <li>To deliver targeted advertising</li>
            </ul>
            <p>
              Your Cookie Choices
              <span>
                You have control over the Cookies placed on your device. You can
                set your browser to acceptl Cookies or reject. Each browser has
                different instructions for managing Cookies. Please consult the
                "Help" section of your browser for more information.
              </span>
            </p>
            <p>
              <span>
                <i>
                  Please note that disabling certain Cookies may affect your
                  browsing experience on the platform and your ability to access
                  certain features.
                </i>
              </span>
            </p>

            <p>
              Changes to this Policy
              <span>
                We may update this policy from time to time to reflect changes
                in our practices or the law. We encourage you to review this
                policy periodically for the latest information.
              </span>
            </p>
            <p>
              Contact Us
              <span>
                If you have any questions about this policy, please contact us
                at <a href={`mailto:${ADMIN_EMAIL}`}>{ADMIN_EMAIL}</a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
