import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import heroImage2 from "../../Assets/sellerHeroImage.png";
import loadingImgPath from "../../Assets/rolling.svg";
import navigation from "../../Configs/Navigation.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import SellerReview from "./sellerReview";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import InputContext from "../Auth/InputContext";
import "./home.css";

// import Alert
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_NAME = process.env.REACT_APP_NAME;
const SUPPORT_EMAIL = process.env.REACT_APP_ADMIN_EMAIL;
const SUPPORT_PHONE = process.env.REACT_APP_ADMIN_PHONE;

const Home = () => {
  const faqEle = useRef();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);

  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [kpisData, setKpisData] = useState([]);
  const [stories, setStories] = useState([]);
  const [FaqData, setFAQData] = useState([]);

  const openSignupPage = () => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      const url = navigation.signup;
      window.open(url, "_blank");
    } else {
      const dashboardUrl = navigation.dashbord;
      window.open(dashboardUrl, "_blank");
    }
  };

  const handleLoginSignupSubmission = async (value, type) => {
    let payload = {};
    if (type === "email") payload["email"] = value;
    else payload["phone"] = value;
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersAuthValidationApi}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setIsLoading(false);

      // redirect to signup page
      if (type == "email" || type == "phone") {
        sessionStorage.setItem("home_input", value);

        var popup = window.open(`${navigation.signup}?from=home`, "_blank");

        if (!popup || popup.closed || typeof popup.closed == "undefined") {
          window.location.href = `${navigation.signup}?from=home`;
        }

        removeDataFromCookie("isLoggedIn");
        removeDataFromCookie("R3p7uLw9Xk");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
      }
    } else if (res.status === 409) {
      setIsLoading(false);
      sessionStorage.setItem("home_input", value);
      const isLoginEmail = getDataFromCookies("email");
      if (isLoginEmail == value) {
        window.open(`${navigation.dashbord}?from=home`, "_blank");
      } else {
        sessionStorage.setItem("home_input", value);
        removeDataFromCookie("isLoggedIn");
        removeDataFromCookie("R3p7uLw9Xk");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
        contextObj.setInput("loginFromHome", true);
        contextObj.setInput("isSignupNavigation", true);
      }
    } else if (res.status === 404) {
      setIsLoading(false);
      setEmailOrPhoneError(res.message);
    } else if (res.status === 400) {
      setIsLoading(false);
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = message;
          });
        });
      }
      setEmailOrPhoneError(errorMessage);
    } else if (res.status === 403) {
      setIsLoading(false);
      setEmailOrPhoneError(Alert.alertForBlockedUser);
    } else {
      setIsLoading(false);
      setEmailOrPhoneError(Alert.alertForSomethingWrong);
      console.error(res);
    }
  };

  const handleLoginSignup = () => {
    setEmailOrPhoneError("");
    setIsLoading(true);

    const emailOrPhoneInput = document.querySelector(
      ".seller-validation-input"
    );
    const emailPhoneInputValue = emailOrPhoneInput.value.trim();

    let errors = false;
    const isEmail = validator.isEmail(emailPhoneInputValue);
    const phoneRegex = /^(\+?91|0)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(emailPhoneInputValue);
    if (emailPhoneInputValue === "") {
      setIsLoading(false);
      emailOrPhoneInput.setAttribute("id", "red-border");
      setEmailOrPhoneError(Alert.alertForEmailandPhone);
      errors = true;
    } else if (isEmail) {
      if (errors === true) return;
      handleLoginSignupSubmission(emailPhoneInputValue, "email");
    } else if (isPhoneNumber) {
      if (errors === true) return;
      handleLoginSignupSubmission(emailPhoneInputValue, "phone");
    } else {
      setIsLoading(false);
      emailOrPhoneInput.setAttribute("id", "red-border");
      setEmailOrPhoneError(Alert.alertForValidEmail);
      errors = true;
    }
  };

  // handle emailOrPhoneInput
  const handleEmailOrPhoneInput = () => {
    const input = document.querySelector(".seller-validation-input");
    input.removeAttribute("id", "red-border");
    setEmailOrPhoneError("");
    setIsLoading(false);
  };

  // call handleSubmission on Enter keypress
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      handleLoginSignup();
    }
  };

  // Get KpIS pRODUCTS

  const getkpisProductsData = async () => {
    try {
      const response = await fetch(`${API_HOST}/${ApiEndpoint.getKpisData}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      setKpisData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        // navigate("/error");
        contextObj.setInput("serviceUnavailable", true);
      } else {
        window.location.href = "/error";
      }
    }
  };

  // Get FAQ Data

  const getFaqData = async () => {
    try {
      const response = await fetch(`${API_HOST}/${ApiEndpoint.getFaqData}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      setFAQData(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        // navigate("/error");
        contextObj.setInput("serviceUnavailable", true);
      } else {
        window.location.href = "/error";
      }
    }
  };

  const getSellerTestimonialsData = async () => {
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.getSellerTestimonials}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setStories(res.data);
    }
  };

  useEffect(() => {
    getkpisProductsData();
    getSellerTestimonialsData();
    getFaqData();
  }, []);

  // handle FAQ's options
  const [activeIndex, setActiveIndex] = useState(null);
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Header />
      <section id="hero-section">
        <div className="hero-container">
          <div className="hero-text-content">
            <h1 className="hero-heading">
              From Home to Heart with {APP_NAME}: Share, Care, Impart Baby Joy.
            </h1>
            <p className="hero-description">
              Join our caring community, share the joy of baby utility.
            </p>
            <div className="start-selling-input">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLoginSignup();
                }}
                className="home-input-form"
              >
                <input
                  autoComplete="false"
                  id="sellers-emailPhone-validationBox"
                  type="text"
                  className="seller-validation-input"
                  placeholder="Email or phone"
                  onChange={handleEmailOrPhoneInput}
                  maxLength={50}
                  onKeyPress={handleKeyPress}
                />
                <button
                  id="seller-submitEmailOrPhoneButton"
                  type="submit"
                  className="seller-validation-btn btn green-btn"
                  style={isLoading ? { opacity: "0.5" } : {}}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleLoginSignup();
                  }}
                >
                  {isLoading ? (
                    <img
                      src={loadingImgPath}
                      alt="image"
                      className="loading-img-size"
                    />
                  ) : (
                    "Start Selling"
                  )}
                </button>
              </form>
              <label className="home-auth-error">{emailOrPhoneError}</label>
            </div>
          </div>
          <div className="hero-image-area">
            <img className="hero-image" src={heroImage2} alt="" />
          </div>
        </div>
      </section>

      <section id="business-count">
        <div className="analytics-container">
          <div className="block1">
            <h3>{kpisData.numOfSellers}</h3>
            <p id="business-count-p1">
              Sellers trust {APP_NAME} to sell online
            </p>
          </div>
          <div className="block2">
            <h3>{kpisData.numOfCustomers}</h3>
            <p id="business-count-p2">Customers buying across India</p>
          </div>
          <div className="block3">
            <h3>{kpisData.numOfPincodesSupported}</h3>
            <p id="business-count-p3">Pincodes supported for delivery</p>
          </div>
          <div className="block4">
            <h3>{kpisData.numOfCategoriesSupported}</h3>
            <p id="business-count-p4">Categories to sell online</p>
          </div>
        </div>
      </section>

      {stories.length > 0 && (
        <section id="seller-reviews">
          <div className="reviews-conatiner">
            <h1 id="seller-reviews-heading">
              Experiences sellers love to talk about
            </h1>
            <SellerReview data={stories} />
            <div className="call-to-action">
              <button
                id="sellers-StartSellingButton"
                className="btn green-btn"
                onClick={openSignupPage}
              >
                Start Selling
              </button>
            </div>
          </div>
        </section>
      )}

      <section id="how-it-works">
        <div className="how-it-works-container">
          <h1 id="how-it-Works-heading">How it works</h1>
          <div className="how-it-works-steps">
            <div className="block-v1">
              <div className="how-it-works-block-one">
                <h2>1</h2>
                <hr className="steps-line" />
              </div>
              <div className="block-v2-div-for-list">
                <h3 id="createAccount">Create Account</h3>
                <p id="create-account-details">
                  All you need is :<br /> 1) Your Address Information
                  <br /> 2) Bank Account
                </p>
              </div>
            </div>
            <div className="block-v2">
              <div className="how-it-works-block-one">
                <hr className="steps-line steps-line11" />
                <h2>2</h2>
              </div>
              <div className="block-v2-div-for-list">
                <h3>List Products</h3>
                <p>List the products you want to sell in your seller panel</p>
              </div>
            </div>
            <div className="block-v3">
              <div className="how-it-works-block-one">
                <hr className="steps-line steps-line11" />
                <h2>3</h2>
              </div>
              <div className="block-v2-div-for-list">
                <h3>Get Orders</h3>
                <p>
                  Start getting orders from crores of Indians actively shopping
                  on our platform.
                </p>
              </div>
            </div>
            <div className="block-v4">
              <div className="how-it-works-block-one">
                <hr className="steps-line steps-line11" />
                <h2>4</h2>
              </div>
              <div className="block-v2-div-for-list">
                <h3>Lowest Cost Shipping</h3>
                <p>Products are shipped to customers at lowest costs</p>
              </div>
            </div>
            <div className="block-v5">
              <div className="how-it-works-block-one">
                <h2>5</h2>
              </div>
              <div className="block-v2-div-for-list">
                <h3>Receive Payments</h3>
                <p>
                  Payments are deposited directly to your bank account following
                  a 7-day payment cycle from order delivery.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pricing">
        <div className="pricing-container">
          <h1>Pricing</h1>
          <div className="pricing-content">
            <p className="bold-line">
              Selling online can be a breeze with our simplified process! Just
              follow these easy steps and you'll be ready to roll -
            </p>
            <p className="line-heading">Fix the right price for your product</p>
            <p>
              Calculate your item's resale value based on its usage and
              condition. Here are our pricing suggestions:
            </p>
            <ul>
              <li>New (Sealed): ~90% of the current market price</li>
              <li>Almost Like New: ~70% of the current market price</li>
              <li>Gently Used : ~50% of the current market price</li>
              <li>Loved: ~40% of the current market price</li>
            </ul>
            <p className="bold-line">
              Note: Your product will be listed 10-20% higher pricing on our
              portal."Current Market Price" will be checked online at the
              instant when Product Request is received. In case if there are
              multiple vendors selling the same product, then the one with the
              lowest will be considered as the current market price for
              computation.
            </p>
          </div>
        </div>
      </section>

      <section id="faq-section">
        <div className="faq-container">
          <h1>Frequently Asked Questions</h1>
          <ul class="acc">
            {FaqData.map((data, index) => (
              <li className="accordion-item" key={index}>
                <button
                  className={`acc_ctrl ${
                    activeIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleToggle(index)}
                >
                  {data.question}
                </button>
                <div
                  className={`acc_panel ${activeIndex === index ? "open" : ""}`}
                  style={{
                    maxHeight:
                      activeIndex === index
                        ? `${faqEle.current.scrollHeight}px`
                        : "0",
                    transition: "max-height 0.3s ease-in-out",
                  }}
                >
                  <div className="acc_panel_body">
                    <p ref={faqEle}>{data.answer}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="call-to-action">
            <button
              id="sellers-StartSellingButton"
              className="btn green-btn"
              onClick={openSignupPage}
            >
              Start Selling
            </button>
          </div>
        </div>
      </section>

      <section id="photography-guidelines">
        <div className="pricing-container">
          <h1>Photography Guidelines</h1>
          <div className="pricing-content">
            <p className="bold-line">
              To enhance your sales potential on JOYREJOY, here's a
              comprehensive guide on creating compelling image listings:
            </p>
            <p className="line-heading">Perfect Clicks:</p>
            <p>
              Choose a neutral, uncluttered, and well-lit background for your
              product photos.
            </p>
            <p>
              Ensure your items are in sharp focus, avoiding any blurry shots.
            </p>
            <p className="line-heading">Select the Best Photos:</p>
            <p>We need minimum of 6 photos in your listing.</p>
            <p>
              Capture your item from various angles to provide a clear
              depiction.
            </p>
            <p>
              Show the size, color, make, and any signs of wear and tear
              accurately.
            </p>
            <p className="line-heading">Pro-Tip:</p>
            <p>
              Approach your product photography from the perspective of how you
              would like to see items when purchasing.
            </p>
            <p>
              Prioritize clarity, accuracy, and representation in your visuals.
            </p>
            <p className="bold-line" style={{ marginTop: "15px" }}>
              By following these guidelines, you can effectively showcase your
              products on JOYREJOY and increase your chances of successful
              sales.
            </p>
          </div>
        </div>
      </section>

      <section id="packaging-guidelines">
        <div className="packaging-guidelines-container">
          <h1>Packaging Guidelines</h1>
          <p className="line-heading">
            Streamline your online selling experience with our straightforward
            process. Follow these simple steps for seamless transactions:
          </p>
          <ul className="pricing-content">
            <li>
              It is mandatory to use sturdy cardboard boxes or corrugated boxes.
            </li>
            <li>
              Please refrain from using flimsy or damaged boxes. Use rigid boxes
              with flaps intact.
            </li>
            <li>
              When reusing boxes, please remove any other labels, hazardous
              material indicators, and other previous shipping markings on the
              box that are no longer applicable.
            </li>
            <li>
              Please ensure that your products are thoroughly cleaned &
              sanitized before shipping.
            </li>
            <li>
              Please make sure the item is not packed in an oversized carton. As
              much as possible, use cartons of the appropriate size to avoid
              high courier fees.
            </li>
            <li>
              Please ensure products are safely padded and are not moving around
              in the boxes.
            </li>
            <li>
              For books and clothing you could also use blister packs if
              possible.
            </li>
            <li>
              Please don't use fancy boxes or packaging that won't hold up.
            </li>
            <li>
              Fabric bags can be damaged during transit, so please do not use
              them.
            </li>
            <li>
              Please strap the boxes securely. Do not use string or paper over
              wrap.
            </li>
            <li>Make sure the boxes are well taped with a strong adhesive.</li>
            <li>
              Stick shipping label with recipient details provided by JOYREJOY.
            </li>
          </ul>
        </div>
      </section>

      <section id="help-and-support">
        <div className="help-and-support-container">
          <h1 id="seller-support-heading">{APP_NAME} Seller Support</h1>
          <div className="support-content">
            <p id="Seller-support-para">
              {APP_NAME} seller support is available to solve all your doubts
              and issues before and after you start your online selling
              business. You can reach out to -
            </p>
            <div className="contact-area">
              <p className="support-contact-line">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="contact-icons"
                  style={{
                    fontSize: "14px",
                  }}
                />
                <a href={`mailto:${SUPPORT_EMAIL}`} className="hyperlink-color">
                  {SUPPORT_EMAIL}
                </a>
              </p>
              <p className="support-contact-line">
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{
                    fontSize: "14px",
                  }}
                  className="contact-icons"
                />
                <a href={`tel:${SUPPORT_PHONE}`} className="hyperlink-color">
                  +91 {SUPPORT_PHONE}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="blank-section"></div>
      </section> */}

      <Footer />
    </>
  );
};

export default Home;
