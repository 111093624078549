import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CategoriesDescription = ({ closedCategoryDetailsPopup }) => {
  return (
    <>
      <div className="Category-modal-popup">
        <div className="Category-modal-popup-second">
          <h1 className="Category-modal-header"> Category List</h1>
          <span
            className="category-modal-close"
            onClick={closedCategoryDetailsPopup}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <div className="all-category-details-container">
            <div className="category-el-box">
              <span className="Category-name-heading"> Cradle : </span>
              <span className="Category-details-description">
                It's a small bed designed to provide a comfortable and secure
                sleeping environment for infants. The curved or swinging
                supports of palnas allow for a gentle rocking motion, it also
                helps to soothe the baby to sleep.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">It includes : </span>
                Indian Palna, Indian Baby Jula.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Cot and Crib : </span>
              <span className="Category-details-description">
                Beds designed for babies or young children,each serving
                different needs and stages of development. It typically features
                sturdy sides made of wood, metal, or other materials to provide
                a safe sleeping environment for the baby.The choice of bed
                depends on factors such as the child's age, sleeping
                preferences, and available space.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Cots, Cribs, Crib Bedding.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">
                Bassinet and Infant Nest :{" "}
              </span>
              <span className="Category-details-description"></span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                For infants it is used. Infant Nest, Basket, Bedside Sleeper,
                Bedside Bassinet.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">
                Rocker and Bouncer :{" "}
              </span>
              <span className="Category-details-description">
                These are type of baby seat or chair that has a curved base,
                allowing it to rock back and forth. Rockers are crafted to calm
                and amuse infants through gentle rocking movement. They
                frequently incorporate additional attributes such as integrated
                toys, musical elements, or vibration options to enhance
                entertainment or relaxation for the baby.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Bouncer.
              </li>
            </div>{" "}
            <div className="category-el-box">
              <span className="Category-name-heading">
                High and Booster Chair :{" "}
              </span>
              <span className="Category-details-description">
                High chairs and booster seats are both types of seating
                solutions for young children, primarily during meal times,
                although they fulfill distinct roles. Both help to ensure the
                safety and comfort of young children during mealtimes.
                <br />
                They usually have safety straps or harnesses to prevent the
                child from falling out or sliding down and they are also
                portable.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Carrier : </span>
              <span className="Category-details-description">
                Carriers designed to be worn around the waist or attached to a
                belt allow parents or caregivers to carry their babies
                hands-free while ensuring support and comfort for both the
                caregiver and the baby. These carriers distribute the baby's
                weight evenly, often easing strain on the caregiver's back and
                shoulders.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Carry on belts.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading"> Stroller : </span>
              <span className="Category-details-description">
                Baby strollers are the preferred option for parents looking for
                a dependable and adaptable means of child transportation.
                Typically suitable for toddlers who can sit independently, these
                strollers frequently feature multiple reclining options and are
                ideal for outings to the supermarket, park, or extended outdoor
                excursions.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Strollers and Prams.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading"> Car Seat : </span>
              <span className="Category-details-description">
                Car seats are specifically engineered to provide protection for
                infants, toddlers, and older children while traveling in
                vehicles. Car seats are a critical safety measure for ensuring
                the well-being of children during car journeys, and their use is
                mandated by law in numerous countries to help prevent injuries
                and fatalities in the event of accidents.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">
                {" "}
                Cycle, Bike and Ride-on :{" "}
              </span>
              <span className="Category-details-description">
                A "cycle" or "bike" for kids refers to a bicycle specifically
                designed for children. These bikes are scaled down in size and
                features to suit the needs and capabilities of young riders.
                Kids' bikes play a crucial role in promoting physical activity
                and outdoor play while aiding in the development of essential
                motor skills, balance, coordination, and confidence in young
                children.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Cycle, Bicycle, Tricycle, Scooters, Manual Ride-ons, Bike,
                Balance Bikes.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Playmat : </span>
              <span className="Category-details-description">
                Playmats are versatile and highly beneficial accessories for
                infants and toddlers. Child can learn different shapes / colors
                / letters /numbers if . It also provides Safe and independent
                play area. It is portable and very easy to clean as well.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Play Gym : </span>
              <span className="Category-details-description">
                A "play gym" is a baby accessory crafted to offer a secure and
                engaging space for infants to engage in play and discovery.
                These structures often include a soft mat or padded surface
                beneath overhead arches or bars. Suspended from these arches or
                bars are a variety of toys, rattles, mirrors, and interactive
                elements designed to stimulate infants' senses and encourage
                exploration and manipulation.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Bed Rail : </span>
              <span className="Category-details-description">
                A "bed rail" is a safety apparatus designed to safeguard
                individuals, especially little one or young children or the
                elderly, from rolling out of bed while asleep.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Swing : </span>
              <span className="Category-details-description">
                A children's swing is a form of leisure equipment crafted for
                kids to enjoy. It generally comprises a suspended seat, often
                hung from ropes or chains attached to a frame or tree limb.
                Children's swings are available in diverse styles, such as
                classic belt swings, bucket swings tailored for younger
                children, tire swings, and glider swings.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">
                Slide and Climber :{" "}
              </span>
              <span className="Category-details-description">
                A puzzle is a type of game or activity that involves arranging
                and assembling pieces to form a complete picture, shape, or
                solution. Puzzles come in various forms and levels of
                complexity, and they are popular for entertainment, cognitive
                development, and problem-solving skills.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Outdoor play items of slider and climber for toddlers and
                preschoolers.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">
                Storage and Furniture :{" "}
              </span>
              <span className="Category-details-description"></span>
              <li className="Category-details-List-contain">
                {" "}
                It includes Chair, Table, Writing Table, Cupboard, Storage.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Block and Set : </span>
              <span className="Category-details-description">
                Building blocks and LEGO are types of construction toys. These
                toys encompass various types of interlocking pieces used for
                building structures. <br /> These blocks can be made from
                different materials such as wood, plastic, or foam, and they
                come in a variety of shapes, sizes, and colors. Building blocks
                may include generic blocks like cubes, rectangles, and
                triangles, as well as specialty blocks with specific features
                like arches, wheels, or gears.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Lego or any constructions sets.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Puzzle : </span>
              <span className="Category-details-description">
                Puzzles has wide range of activities where individuals arrange
                and assembles pieces to achieve a specific goal, whether it's
                complicating a picture or solving any problem or inraveling any
                mystry. This is activity that offer both enjoyment and mental
                exercise for people of any age.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Montessori Toy : </span>
              <span className="Category-details-description">
                Montessori toys are intentionally crafted to encourage hands-on
                learning experiences, foster independence, and support the
                natural development of children's abilities. These toys often
                have simple designs with minimalistic aesthetics and mostly made
                from natural materials like wood.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading"> Board Game : </span>
              <span className="Category-details-description">
                A board game is a type of tabletop game that involves players
                moving pieces or tokens on a pre-marked surface or "board"
                according to a set of rules. These games often incorporate
                elements of strategy, chance, and competition. <br /> Examples
                of popular board games include classics like Monopoly, Scrabble,
                and Chess, as well as modern games like Settlers of Catan.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Flash Card : </span>
              <span className="Category-details-description">
                Flashing cards (at a speed of less than one second per card) is
                a more effective teaching method than showing cards at a
                leisurely pace, for two reasons: <br />• Information presented
                at speed is more easily apprehended by the right hemisphere of
                the brain. Unlike left-brain memorization, which requires
                conscious, directed effort. right-brain learning is unconscious
                and effortless. <br />• Young children learn at an extremely
                rapid pace - much faster than adults: much faster even than
                adults can imagine. The way to keep a child's attention is to
                move quickly.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Baby Monitor : </span>
              <span className="Category-details-description">
                A 'Perfect way to keep eye on your baby' during work hours or
                when your are away from your baby. Using this device, Parents
                can remotely monitor their infants or young children while they
                sleep or rest in another room. These devices typically consist
                of two main components: a transmitter, which is placed in the
                baby's room near the crib or sleeping area, and a receiver,
                which is kept with the parent.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Swim Essential : </span>
              <span className="Category-details-description"></span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Swim Seat, Rings, Sleeves and Swim Tubs, Inflatable Pool, Swim
                Specs.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Belt and Support : </span>
              <span className="Category-details-description">
                These postpartum betls are Supportive garments worn by women
                after childbirth to provide support and compression to the
                abdomen, pelvis, and lower back. <br /> They aim to promote
                comfort, aid in recovery, and assist in restoring the body's
                pre-pregnancy shape and strength.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Maternity bands, Postpartum belts, Support belts, Tummy or Belly
                belts, Band.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Maternity Pillow : </span>
              <span className="Category-details-description">
                Feeding pillow plays a crucial role in promoting conformatble
                and successful feeding experiences both for Mom and baby,
                regardless of whether feeding method is breastfeeding or bottle
                feeding. By providing support, comfort, and convenience, feeding
                pillows contribute to making feeding sessions more enjoyable and
                less physically taxing for both.
              </span>
              <li className="Category-details-List-contain">
                {" "}
                <span className="category_includes-font">
                  It includes :{" "}
                </span>{" "}
                Feeding Pillow.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">Sport Equipment : </span>
              <span className="Category-details-description"></span>
              <li className="Category-details-List-contain">
                {" "}
                It includes All outdoor sports activity equipment to be used by
                3 or more years of age group. Skets, Sketboards, All type of
                Balls with certain price range, Cricket set, Basketball set,
                Surf boards, Boxing set and sand bag, Badminton racket, Carrom
                board etc.
              </li>
            </div>
            <div className="category-el-box">
              <span className="Category-name-heading">
                Tent and Playhouse :{" "}
              </span>
              <span className="Category-details-description">
                Tents and playhouses provide children with chances for creative
                play, social interaction, and outdoor discovery, yet they vary
                in their structure, dimensions, and intended purpose. Tents
                offer flexibility and portability, whereas playhouses are
                sturdier and frequently become permanent fixtures in outdoor
                play settings.
              </span>
              <li className="Category-details-List-contain"> </li>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesDescription;
