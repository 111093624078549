import React from "react";
import { useState, useEffect } from "react";
import Footer from "../Home/Footer/Footer";
import ResourcesSidebar from "./Sidebar";
import "./resources.css";
import {
  generalFAQData,
  SafetyFAQData,
  CustomerServiceData,
  AdditionalFAQs,
} from "./questionAnswerList";

const FAQs = () => {
  
  const [generalFAQDataList, setgeneralFAQDataList] = useState([]);
  const [safetyFAQDataList, setSafetyFAQDataList] = useState([]);
  const [customerServiceList, setCustomerServiceList] = useState([]);
  const [additionalFAQsList, setAdditionalFAQsList] = useState([]);

  useEffect(() => {
    if (generalFAQData) {
      setgeneralFAQDataList(generalFAQData);
    }
    if (SafetyFAQData) {
      setSafetyFAQDataList(SafetyFAQData);
    }
    if (CustomerServiceData) {
      setCustomerServiceList(CustomerServiceData);
    }
    if (AdditionalFAQs) {
      setAdditionalFAQsList(AdditionalFAQs);
    }
  }, [generalFAQData, SafetyFAQData, AdditionalFAQs]);

  
  // policy header sticky
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const policyHeading = document.querySelector(".policy-content-heading");
      const headingPosition = policyHeading.getBoundingClientRect().top;
      if (headingPosition <= 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div style={{
      backgroundColor : "#fff"
    }}>
      <div
        className="body-container"
        
      >
        <div className="policy-container" >
          <ResourcesSidebar activeResource={"FAQ's"} />
          <div className="policy-content">
            <h1
              className={`policy-content-heading ${
                isSticky ? "sticky-heading" : ""
              }`}
            >
              FAQ's
            </h1>
            {generalFAQDataList && (
              <>
                {generalFAQDataList.map((faq) => {
                  return (
                    <p>
                      {faq.question}
                      <span>{faq.answer}</span>
                    </p>
                  );
                })}
              </>
            )}
            <h2>Safety</h2>
            {safetyFAQDataList && (
              <>
                {safetyFAQDataList.map((faq) => {
                  return (
                    <p>
                      {faq.question}
                      <span>{faq.answer}</span>
                    </p>
                  );
                })}
              </>
            )}
            <h2>Customer Service</h2>
            {customerServiceList && (
              <>
                {customerServiceList.map((faq) => {
                  return (
                    <p>
                      {faq.question}
                      <span>{faq.answer}</span>
                    </p>
                  );
                })}
              </>
            )}
            <h2>Additional FAQ's</h2>
            {additionalFAQsList && (
              <>
                {additionalFAQsList.map((faq) => {
                  return (
                    <p>
                      {faq.question}
                      <span>{faq.answer}</span>
                    </p>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
